.container.content {
  margin-bottom: 60px;
}

.tabs-panel {
  &.navbar-dark {
    margin-top: 20px;

    li {
      position: relative;
      padding: 6px 16px;

      .nav-link,
      .nav-link:focus {
        color: #464646;
        border-radius: unset;
        font-size: 1.5rem;
        line-height: 1.75rem;
        text-decoration: none;
        border-bottom: 1px solid #CCCCCC;
        padding: 0;
        outline: none;

        &:hover {
          color: #EE1C24;
          border-bottom: 1px solid #EC6A72;
        }
      }

      &.active {
        background-color: #EE1C24;

        .nav-link {
          color: #FFFFFF;
          border-bottom: unset;

          &:hover {
            border-bottom: unset;
          }
        }

        .arrow-down {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #EE1C24;
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &.active-tradein {
        background-color: #1178C6;

        .arrow-down {
          border-top: 10px solid #1178C6;
        }
      }
    }

    .car-tabs {
      .active {
        .nav-link:hover {
          color: #FFFFFF;
        }
      }

      .nav-link {
        color: #FFFFFF;

        &:hover {
          color: #CCCCCC;
          border-bottom: 1px solid #CCCCCC;
        }
      }
    }

    .list-vendors {
      li {
        &.active {
          &:last-child {
            background-color: #1178C6;

            .arrow-down {
              border-top: 10px solid #1178C6;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .tabs-panel {
    padding: 0;
  }
}

@media (max-width: 991px) {
  .car-item-current-cont {
    .tabs-panel {
      &.navbar-dark {
        background-color: #EE1C24;

        .navbar-brand {
          color: #FFFFFF;
        }

        .car-tabs {
          li {
            a {
              color: rgba(255, 255, 255, .8);
            }
          }
        }
      }
    }
  }

  .tabs-panel {
    &.navbar-dark {
      background-color: #343A40;

      .navbar-brand {
        text-decoration: none;
        color: #FFFFFF;
      }

      .vehicle-kind-list,
      .salon-content-list,
      .car-tabs,
      .reklama-content-list {
        margin-top: 10px;

        li {
          padding-left: 0;
          padding-right: 0;

          a {
            font-size: 1.125rem;
            border-bottom: unset;
            color: rgba(255, 255, 255, .5);
          }
        }
      }

      .navbar-nav .nav-item a .text-light-gray {
        font-size: 0.75rem;
      }
    }
  }
}
