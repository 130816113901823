.toggleize {
  ul {
    margin-bottom: 0;
  }

  .toggleize-head {
    overflow: hidden;
    height: 1.3rem;
    line-height: 1.3rem;
    box-sizing: border-box;
    max-width: 100%;
    white-space: nowrap;
    position: relative;

    li {
      padding: 0;
      height: 1.3rem;
      line-height: 1.3rem;
      position: relative;
      z-index: 2;

      a {
        color: #464646;
        border-bottom: 1px dotted #EE1C24;
        text-decoration: none;

        &:hover {
          color: #EE1C24;
          text-decoration: none;
        }
      }

      &.current {
        background: #999999;
        border-radius: 3px;
        padding: 0 5px;
        color: #FFFFFF;

        a {
          color: #FFFFFF;
          font-size: 0.75rem;
        }

        &.not-visible {
          -webkit-box-ordinal-group: 0;
          -ms-flex-order: -1;
          order: -1;
        }
      }

      &.free-range-select {
        &.current {
          background: unset;
          color: #464646;

          .btn-secondary {
            &:focus, &.focus {
              box-shadow: unset;
            }
          }
        }
      }

      &:after {
        margin-left: 3px;
        position: relative;
        top: -3px;
        content: attr(data-count);
        font-size: 0.56rem;
        color: #CCCCCC;
      }

      &[data-count="-1"]:after {
        display: none;
      }

      &.no-more {
        position: relative;
        z-index: 4;
        background: #F5F5F5;
        margin-right: -100%;
      }

      &.free-range-select {
        label {
          margin: 0 0.5rem;
        }
        input {
          height: 1.3rem;
          padding: 0 0.25rem;

          &::placeholder {
            color: transparent;
          }
        }

        a {
          color: #FFFFFF;
          line-height: 1.3rem;
          font-size: 0.75rem;
          border-bottom: unset;
          height: 1.3rem;
          margin-left: 0.25rem;
          padding: 0 0.375rem;
          vertical-align: top
        }
      }
    }

    a.more {
      position: absolute;
      top: 0.1875rem;
      line-height: 0.875rem;
      z-index: 0;
      right: 1rem;
      color: #464646;
      border-bottom: 1px dotted #EE1C24;

      &:hover {
        color: #EE1C24;
        text-decoration: none;
      }

      &:after {
        content: url(../../../images/frontend/arrow-down.png);
        display: block;
        position: absolute;
        right: -10px;
        top: -1px;
      }
    }
  }

  &.no-show-all {
    a.more {
      display: none;
    }
  }

  .toggleize-all {
    border-top: 1px solid #CCCCCC;
    position: relative;

    li {
      height: 100%;

      a {
        color: #464646;
        border-bottom: 1px dotted #EE1C24;

        &:hover {
          color: #EE1C24;
          text-decoration: none;
        }
      }
   
      &:after {
        margin-left: 3px;
        position: relative;
        top: -3px;
        content: attr(data-count);
        font-size: 0.56rem;
        color: #CCCCCC;
      }

      &[data-count="-1"]:after {
        display: none;
      }

      &:before {
        position: absolute;
        left: 0;
        color: #EE1C24;
        font-weight: bold;
        content: attr(data-char);
      }

      &.filtered-out {
        display: none;
      }
    }

    .unavailable {
      li {
        a {
          color: #9F9F9F;
          border-bottom: 1px dotted #9F9F9F;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 19px;
      height: 10px;
      right: 15px;
      top: -10px;
      background: url(../../../images/frontend/toggleize-dropdown-arrow.png) no-repeat;
    }

    h4 {
      font-size: 0.825rem;
      font-weight: bold;
      line-height: 1.65rem;
    }

    input[type="radio"] {
      margin-right: 0.6rem;
      position: relative;
      top: 2px;
    }

    input.form-control {
      font-size: 0.75rem;
    }
  }

  &.no-show-all {
    .toggleize-head {
      overflow: visible;
      height: auto;
    }
  }

  .toggleize-item.disabled {
    &:after {
      display: none;
    }

    a {
      color: lightgray;
      border-bottom: 1px dotted lightgray;
      cursor: default;

      &:hover {
        color: lightgray;
      }
    }
  }

  &.hide-empty {
    .toggleize-head {
      .toggleize-item {
        &.disabled {
          display: none;
        }

        &.disabled.current {
          display: initial;
        }
      }
    }

    .toggleize-head {
      .more {
        z-index: 3; /* для элементов с опцией hide_empty: true всегда показываем кнопку "Все" */
      }
    }
  }
}

.toggleize-label {
  .badge {
    font-size: inherit;
    font-weight: normal;
    color: #1178C5;

    &:hover, &:focus {
      color: #1178C5;
    }

    &:after {
      color: #464646;
      margin-left: 0.25rem;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: "\F057";
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      -webkit-font-feature-settings: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
    }
  }
}

.toggleize-checkbox {
  &:after {
    margin-left: 3px;
    position: relative;
    top: -3px;
    content: attr(data-count);
    font-size: 8.96px;
    font-size: 0.56rem;
    color: #ccc;
  }

  &[data-count="-1"]:after {
    display: none;
  }

  &[data-count="0"] {
    &:after {
      display: none;
    }
  }

  &.disabled {
    color: lightgray !important;

    span {
      border-bottom: 1px dotted lightgray !important;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .toggleize {
    .toggleize-head {
      li {
        &.free-range-select {
          input {
            width: 2.8rem;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .toggleize {
    .toggleize-head {
      li {
        &.free-range-select {
          input {
            width: 4.25rem;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .toggleize {
    .toggleize-head {
      li {
        &.free-range-select {
          label {
            margin: 0 0.25rem;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .toggleize {
    .toggleize-head {
      li {
        &.free-range-select {
          label:first-of-type {
            margin-left: unset;
          }
        }
      }
    }
  }
}