.rec-odd {
  background: #ECECEC;

  a {
    color: #464646;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: #EE1C24;
    }
  }

  h3 {
    .text-red {
      font-weight: bold;
    }
  }

  .bar-graphs-vertical-wrap {
    margin: 40px 0 24px 0;
    list-style: none;
    padding: unset;

    li {
      display: inline-block;
      vertical-align: top;
      width: 210px;
      text-align: left;
      background: url(../../../../images/frontend/bar-graphs-vertical-list.png) 0 11px no-repeat;
      padding-top: 45px;

      .bar-text-padding {
        padding-left: 10px;
      }

      .bar-graphs-vertical-list,
      .bar-graphs-vertical {
        height: 100px;
        overflow: hidden;
      }

      .bar-graphs-vertical-list {
        margin-top: 9px;

        .bar-graphs-vertical {
          display: inline-block;
          vertical-align: top;
          float: left;
          width: 30px;
          position: relative;

          span {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }

        .bar-graphs-gray {
          span {
            background: #C2C2C2;
          }
        }

        .bar-graphs-dark {
          span {
            background: #999999;
          }
        }

        .bar-graphs-red {
          span {
            background: #EE1C24;
          }
        }
      }
    }
  }

  &.honor-wrap {
    p {
      margin: 24px 0 0 0;
      font-size: 1.125rem;

      .text-red {
        font-weight: bold;
      }
    }
  }

  .people-wrap {
    margin-top: 36px;

    .men-header {
      .text-red,
      .text-gray {
        font-size: 1.125rem;
        line-height: 1;
        font-weight: bold;

        span {
          font-size: 4.5rem;
        }
      }
    }

    .men-left-col {
      span {
        b {
          left: -10px;
          margin-left: -80px;
          text-align: right;
          width: 80px;
        }
      }

      .bar-graphs-desc {
        text-align: left;
      }
    }

    .men-right-col {
      span {
        b {
          right: -10px;
          margin-right: -80px;
          text-align: left;
          width: 80px;
        }
      }

      .bar-graphs-desc {
        text-align: right;
      }
    }

    .men-body {
      margin-top: 30px;

      .item {
        margin-top: 10px;

        .bar-graphs-horizontal-190 {
          width: 190px;
          display: inline-block;
          vertical-align: top;
          margin: 0 10px;
          height: 30px;

          span {
            display: inline-block;
            vertical-align: top;
            line-height: 30px;
            position: relative;
            height: 100%;

            b {
              position: absolute;
            }
          }

          &.bar-graphs-red {
            span {
              background: #EE1C24;
            }
          }

          &.bar-graphs-dark {
            span {
              background: #999999;
            }
          }
        }

        .bar-graphs-desc {
          display: inline-block;
          vertical-align: top;
          line-height: 15px;
        }
      }
    }

    .men-footer {
      margin-top: 36px;
    }
  }
}

.rec-even,
.rec-odd {
  padding: 50px 0;
  line-height: 1.5rem;

  h4 {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  h3 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: unset;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.2;
    margin: unset;
  }

  .top-24 {
    margin-top: 24px;

    .link-red__email {
      text-decoration: none;
      color: #EE1C24;
    }

    .phones {
      .city {
        margin-top: 25px;
      }

      .numbers {
        color: #999999;

        a {
          text-decoration: none;
          color: #EE1C24;

          span {
            color: #999999;
          }
        }
      }
    }

    .text-red {
      font-weight: bold;
    }
  }

  .rec-today {
    margin: 25px 0;

    .item {
      font-weight: bold;

      .text-red {
        font-size: 4.5rem;
        line-height: 1.2;
      }

      .desc {
        font-size: 0.875rem;
      }
    }
  }

  p {
    margin: unset;
  }

  .list-cities {
    margin-top: 25px;

    .city-right {
      .bar-graphs-horizontal-400 {
        span {
          b {
            right: -10px;
            text-align: left;
            margin-right: -80px;
          }
        }
      }
    }

    .city-left,
    .city-right {
      font-size: 0.875rem;
      line-height: 1.875rem;
      font-weight: bold;

      .bar-graphs-horizontal-400 {
        display: inline-block;
        line-height: 30px;
        height: 30px;

        span {
          display: inline-block;
          vertical-align: top;
          line-height: 30px;
          position: relative;
          height: 100%;
          max-width: 100%;

          b {
            width: 80px;
            position: absolute;
          }
        }
      }

      &:first-child,
      &:nth-child(2) {
        .city-name {
          font-size: 1.125rem;
        }
      }

      .bar-graphs-red {
        span {
          background: #EE1C24;
        }
      }

      .bar-graphs-gray {
        span {
          background: #C2C2C2;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .rec-even,
  .rec-odd {
    .list-cities {
      .city-left {
        .bar-graphs-horizontal-400 {
          span {
            b {
              text-align: right;
              margin-left: -80px;
              left: -10px;
            }
          }
        }
      }

      .city-left,
      .city-right {
        .bar-graphs-horizontal-400 {
          width: 400px;
        }
      }
    }
  }

  .rec-odd {
    .people-wrap {
      background: url(../../../../images/frontend/people.png) center no-repeat;
      min-height: 264px;
    }
  }
}

@media (max-width: 991px) {
  .rec-even,
  .rec-odd {
    .list-cities {
      padding: 0 15px;

      .city-left {
        .bar-graphs-horizontal-400 {
          span {
            b {
              right: -10px;
              text-align: left;
              margin-right: -80px;
            }
          }
        }
      }

      .city-left,
      .city-right {
        .bar-graphs-horizontal-400 {
          width: 75%;
        }
      }
    }

    .people-wrap {
      .men-body {
        .item {
          .bar-graphs-red {
            text-align: right;
          }

          .bar-graphs-dark {
            text-align: left;
          }
        }
      }
    }
  }

  .rec-odd {
    &.honor-wrap {
      img {
        width: 100%;
        height: auto;
        padding: 0 15px;
      }
    }
  }
}