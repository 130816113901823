.bnr-variant-show-widget {
  margin-top: 20px;
  margin-bottom: 20px;
}


@media (min-width: 992px) {
  .bnr-variant-show-widget {
    margin-left: -15px;
    margin-right: -15px;
  }
}