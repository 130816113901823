.header-cars {
  margin-top: 18px;
  margin-bottom: 18px;

  h1 {
    &.title {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }

  .sorting-car-list {
    .btn-danger {
      background: #ee1c24;
      border: 1px solid #ce181f;
    }
    .btn-light {
      background: #f5f5f5;
      border: 1px solid #e3e3e3;
      color: #464646;
    }
  }
}

.car-list-wide,
.car-list {
  .car-item {
    a {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;

      &:hover {
        .options {
          .title {
            color: #EE1C24;
          }
        }
      }

      .options {
        position: absolute;
        z-index: 1;
        bottom: 10px;
        left: 10px;

        .title {
          color: #464646;
          margin-bottom: 5px;
          font-weight: bold;
          display: inline-block;
        }

        .price,
        .credit,
        .promo,
        .tradein {
          display: inline-block;
          margin-left: 5px;
        }

        .price {
          margin: 0;
        }
      }
    }
  }
}

.car-list-wide {
  margin-bottom: 10px;

  .car-item {
    &.car-item-current,
    &.car-item-current + .bnr-tall {
      display: none;
    }

    .hd-img,
    .car-3col-photo,
    .car-2col-photo {
      width: 100%;
      background-position: center;
      background-size: cover;
    }

    .hd-img-cut-r,
    .car-3col-photo-cut-r,
    .car-2col-photo-cut-r {
      background-position-x: left !important;
    }

    .hd-img-cut-l,
    .car-3col-photo-cut-l,
    .car-2col-photo-cut-l {
      background-position-x: right !important;
    }

    .options {
      .title {
        padding: 3px 10px;
        font-size: 1.5rem;
        line-height: 1.2;
      }

      .price,
      .credit,
      .promo,
      .tradein {
        padding: 1px 10px;
        line-height: 1.625rem;
      }
    }
  }
}

.car-list {
  .car-item {
    margin-bottom: 10px;

    .car-item-photo {
      width: 100%;
      background-position: center;

      &.car-item-photo-cut-r {
        background-position-x: left !important;
      }

      &.car-item-photo-cut-l {
        background-position-x: right !important;
      }
    }

    .options {
      .title {
        font-size: 0.75rem;
        padding: 3px 7px;
        line-height: 1.25rem;
      }

      .price,
      .credit,
      .promo,
      .tradein {
        font-size: 0.625rem;
        padding: 1px 7px;
        line-height: 1.25rem;
      }
    }
  }
}

@media (min-width: 1200px) {
  .car-list {
    .car-item {
      .car-item-photo {
        height: 200px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .car-list {
    .car-item {
      .car-item-photo {
        height: 161px;
      }

      .inner {
        &.text-center {
          max-height: 161px;
          overflow: hidden;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .car-list-wide {
    .car-item {
      &.car-item-3col {
        padding-right: 10px;
      }
    }
  }

  .car-list {
    .car-item {
      &.car-item-current {
        &:before {
          content: '';
          position: absolute;
          height: 10px;
          bottom: -10px;
          overflow: hidden;
          z-index: 1;
          background-color: #EE1C24;
        }

        &:after {
          content: '';
          position: absolute;
          left: 42%;
          bottom: -25px;
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 15px solid #EE1C24;
          clear: both;
          z-index: 1;
        }

        .inner {
          background-color: #000;

          .car-item-photo {
            opacity: 0.65;
          }

          .options {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .header-cars {
    .sorting-car-list {
      margin-top: 10px;
      order: 2;
    }

    .title-description {
      order: 1;
    }
  }
}

@media (min-width: 767px) {
  .car-item-2col {
    padding-right: 5px;
  }

  .car-item-2col + .car-item-2col {
    padding-left: 5px;
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .car-list-wide {
    .car-item {
      .options {
        .title {
          font-size: 0.875rem;
          padding: 3px 7px;
          line-height: 1.25rem;
        }

        .price,
        .credit,
        .promo,
        .tradein {
          font-size: 0.75rem;
          padding: 1px 7px;
          line-height: 1.25rem;
        }
      }
    }
  }

  .car-list {
    .car-item {
      .options {
        .title {
          font-size: 0.875rem;
        }

        .price,
        .credit,
        .promo,
        .tradein {
          font-size: 0.75rem;
        }
      }
    }
  }

  .car-item-2col {
    margin-bottom: 10px;
  }

  .car-item-2col + .car-item-2col {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .car-list-wide {
    .car-item {
      a {
        .hd-img,
        .car-3col-photo {
          height: 303px;
        }
      }
    }
  }

  .car-list {
    .car-item {
      .inner {
        &.text-center {
          max-height: 119px;
          overflow: hidden;
        }
      }

      a {
        .options {
          bottom: 5px;
          left: 5px;

          .title {
            margin-bottom: 3px;
            font-size: 0.625rem;
            padding: 2px 5px;
            line-height: 1rem;
          }

          .price,
          .credit,
          .promo,
          .tradein {
            font-size: 0.5625rem;
            padding: 1px 3px;
            line-height: 1rem;
          }
        }
      }
    }
  }
}

@media (min-width: 465px) and (max-width: 575px) {
  .car-list .car-item,
  .car-list-wide .car-item-2col {
    width: 435px;
    margin-left: auto;
    margin-right: auto;
    padding: unset;
    flex: unset;
  }
}

@media (max-width: 464px) {
  .car-list .car-item,
  .car-list-wide .car-item-2col {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex: unset;
  }
}
