.toggleize-img {
  .toggleize-item {
    padding: 0.625rem !important;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: #fff;
    transition: background-color 0.5s ease;
    margin-left: 0.56rem;
    margin-right: 0.56rem;

    &:hover, &.current {
      border: 1px solid #e2e2e2;
    }

    &.current {
      background-color: #dedede;
      border-color: #999;
    }

    &:before {
      position: absolute;
      right: -3px;
      top: -3px;
      color: #fff;
      background: #999;
      border-radius: 9px;
      padding: 1px 5px 0px 5px;
      line-height: 0.9rem;
      margin-left: 3px;
      content: attr(data-count);
      font-size: 0.56rem;
      border: 2px solid #fff;
    }

    &[data-count="-1"]:before {
      display: none;
    }
  }

  .toggleize-head {
    overflow: hidden;
    box-sizing: border-box;
    max-width: 100%;
    white-space: nowrap;
    position: relative;

    &.collapsing {
      display: none;
    }

    &>ul {
      margin-bottom: 0;
    }

    .toggleize-item {
      &.more-item {
        display: flex;
        padding: 0.6rem 0 0 0 !important;
        &:hover {
          border: none;
        }
        margin-right: -1.5rem;

        &:before, &:after {
          display: none;
        }
      }
    }

    a.more {
      line-height: 1.2rem;
      z-index: 0;
      color: #464646;
      font-size: 1rem;
      border: 1px solid #e1e1e1;
      background: #f5f5f5;
      padding: 1rem 0;
      border-radius: 3px;
      text-decoration: none;
      text-align: center;
      white-space: normal;
      width: 4.25rem;
      height: 4.25rem;
    }
  }

  .toggleize-all {
    /* компенсируем отступ нижнего ряда */
    margin-bottom: -0.6rem;

    .toggleize-item {
      margin-bottom: 0.6rem;
    }

    &>ul {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 319px) and (min-width: 0px) {
  .toggleize-img {
    .toggleize-item {
      margin-bottom: 0.4rem !important;
      margin-right: calc(((100% / 3) - 70px)/2);
      margin-left: calc(((100% / 3) - 70px)/2);
    }
    .toggleize-head {
      height: 11.1rem;
      ul li:nth-child(n+6) {
        display: none;
      }

      /* если все элементы вмещаются в видимую область, то кнопка "показать все"
         не будет выводиться благодаря этому правилу */
      ul li.more-item:nth-child(-n+6) {
        display: none;
      }
    }
  }
}

@media (max-width: 419px) and (min-width: 320px) {
  .toggleize-img {
    .toggleize-item {
      margin-bottom: 0.4rem !important;
      margin-right: calc(((100% / 4) - 70px)/2);
      margin-left: calc(((100% / 4) - 70px)/2);
    }
    .toggleize-head {
      height: 11.1rem;
      ul li:nth-child(n+8) {
        display: none;
      }

      ul li.more-item:nth-child(-n+8) {
        display: none;
      }
    }
  }
}

@media (max-width: 575px) and (min-width: 420px) {
  .toggleize-img {
    .toggleize-item {
      margin-bottom: 0.4rem !important;
      margin-right: calc(((100% / 5) - 70px)/2);
      margin-left: calc(((100% / 5) - 70px)/2);
    }
    .toggleize-head {
      height: 11.1rem;
      ul li:nth-child(n+10) {
        display: none;
      }
      ul li.more-item:nth-child(-n+10) {
        display: none;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .toggleize-img {
    .toggleize-item {
      margin-left: 0;
      margin-right: 0.22rem;
    }

    .toggleize-head {
      height: 5.4rem;
      ul li:nth-child(n+7) {
        display: none;
      }

      ul li.more-item:nth-child(-n+7) {
        display: none;
      }
    }

    .toggleize-all {
      ul li:nth-child(7n+0) {
        margin-right: 0;
      }
    }

  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .toggleize-img {
    .toggleize-head {
      height: 5.4rem;
      ul li:nth-child(n+8) {
        display: none;
      }

      ul li.more-item:nth-child(-n+8) {
        display: none;
      }

      .toggleize-item {
        margin-left: 0.55rem;
        margin-right: 0.54rem;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .toggleize-img {
    .toggleize-item {
      margin-left: 0;
      margin-right: 0.547rem;
    }

    .toggleize-head {
      height: 5.4rem;
      ul li:nth-child(n+8) {
        display: none;
      }

      ul li.more-item:nth-child(-n+8) {
        display: none;
      }
    }

    .toggleize-all {
      ul li:nth-child(8n+0) {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .toggleize-img {
    .toggleize-item {
      margin-left: 0;
      margin-right: 0.42rem;
    }

    .toggleize-head {
      height: 5.4rem;
      ul li:nth-child(n+11) {
        display: none;
      }

      ul li.more-item:nth-child(-n+11) {
        display: none;
      }

      .toggleize-item.more-item {
        margin-left: 1px;
      }
    }

    .toggleize-all {
      ul li:nth-child(11n+0) {
        margin-right: 0;
      }
    }
  }
}