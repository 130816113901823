.banner-wrapper {
  .bnr-wide-search-form, .bnr-wide-footer {
    overflow: hidden;
    text-align: center;
  }

  .bnr-wide-footer {
    margin-top: 30px;
  }
}
