.with-label-made_in_rk {
  a::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 10px;
    width: 49px;
    height: 20px;
    z-index: 21;
    background: url(../../../../images/projects/newauto/made_in_rk.png);
  }
}

.text-flag.label-made_in_rk {
  background-color: #00AFC2;
}

@media (max-width: 767px) {
  .made_in {
    background: url(../../../../images/frontend/icons/made_in_kz.svg);
  }
}
