.newauto {
  .admin-signed-in {
    background-color: #E6F6FD;
  }
}

.autovsalone {
  .admin-signed-in {
    background: linear-gradient(to bottom, rgba(153, 153, 153, 0.1) 33%, rgba(0, 0, 255, 0.1) 33%, rgba(0, 0, 255, 0.1) 66%, rgba(255, 0, 0, 0.1) 66%);
    background-color: #F8F8F8;
  }
}


.admin-signed-in {
  border-bottom: 1px solid #E7E7E7;
  padding-top: 0;
  padding-bottom: 0;

  .navbar-brand {
    color: #777777;
    padding: 0;
    font-size: 1.125rem;
    line-height: 3.125rem;
    height: 50px;
  }

  .dropdown-menu {
    margin-top: 0;
    border-color: #E7E7E7;
    border-radius: unset;

    .dropdown-item.active,
    .dropdown-item:active {
      color: #464646;
      background-color: #F8F9FA;
    }

    a {
      font-size: 0.8125rem;

      &:link,
      &:visited {
        color: rgba(0, 0, 0, .5);
      }
    }
  }

  .navbar-toggler {
    padding: 2px 4px;
    font-size: 0.75rem;

    &:focus {
      outline: none;
    }
  }

  .nav-item.dropdown.show > a,
  .nav-item.dropdown.show > a:hover,
  .nav-item.dropdown.show > a:focus {
    background-color: #E7E7E7;
    color: #555555;
  }
}

@media (min-width: 992px) {
  .admin-signed-in {
    .navbar-nav {
      li {
        a {
          &.nav-link {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 3.125rem;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .admin-signed-in {
    .navbar-collapse {
      border-top: 1px solid #E7E7E7;
      margin: 0px -16px 8px;

      .nav-link {
        padding-right: 16px;
        padding-left: 16px;
      }

      .dropdown-menu {
        border: none;
        background-color: transparent;

        a {
          font-size: 0.75rem;

          &:link,
          &:visited {
            color: rgba(0, 0, 0, .5);
          }
        }
      }
    }
  }
}
