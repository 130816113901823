.btn-primary-red {
  color: #FFFFFF;
  background-color: #EE1C24;
  border-color: #EE1C24;

  &:hover {
    color: #FFFFFF;
    border: 1px solid #DE1C24;
    background: #DE1C24;
  }
}

.btn-red {
  overflow: visible;
  padding: 4px 10px;
  outline: none;
  border: 1px solid #EE1C24;
  border-radius: 4px;
  background: #EE1C24;
  color: #FFFFFF;
  font: 1.125rem Tahoma, sans-serif;

  &:hover {
    color: #FFFFFF;
    border: 1px solid #DE1C24;
    background: #DE1C24;
  }
}

.btn-close {
  position: absolute;
  width: 30px;
  height: 29px;
  right: 10px;
  top: 10px;
  background: url(../../../images/frontend/close.png) no-repeat center center;
}