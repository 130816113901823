h1 {
  &.title-journal {
    font-size: 1.875rem;
    margin: 24px 0 0 0;
  }
}

.middle-journal {
  margin-top: 25px;
  border-bottom: 5px solid #EE1C24;

  h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 18px;
  }

  .content-journal {
    padding-top: 30px;

    ul {
      list-style: disc;
      margin: 0 0 18px 15px;
      padding: 0;
    }
  }

  .sideright-journal {
    img {
      width: 100%;
      height: auto;
    }

    .footer-journal {
      margin-top: 10px;

      h3 {
        margin-bottom: 0;
      }
    }
  }
}

.journal-archive {
  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 20px 0;
  }

  a {
    display: block;
  }

  .journal-img {
    img {
      width: 100%;
      height: auto;
    }
  }

  .journal-item-title {
    font-size: 1.125rem;
    line-height: 1.2;
    font-weight: bold;
    margin-top: 5px;
  }
}

@media (max-width: 991px) {
  .middle-journal {
    .content-journal {
      order: 2;
      padding-bottom: 30px;
    }

    .sideright-journal {
      order: 1;
    }
  }
}