.salon-section {
  .breadcrumbs {
    a {
      color: #007bff;
    }
  }

  h1 {
    font-size: 1.875rem;
    border-bottom: 1px solid #dedede;

    .vendor-item {
      display: inline-block;
      transform: translateY(30%);
    }
  }

  h2 {
    font-size: 1.125rem;
    margin-bottom: 0;
  }

  .info {
    color: #464646;
    p {
      font-size: 1rem;
      margin-bottom: 0;
      line-height: 1.4rem;
    }
  }

  .car-item {
    position: relative;

    .options {
      position: absolute;
      bottom: 10px;
      left: 15px;

      color: #464646;

      .title {
        margin-bottom: 0.3125rem;
      }

      .title, .price {
        font-size: 1rem;
        padding: 0.4375rem 0.5rem 0.25rem;
      }
    }

    margin-bottom: 10px;
  }

  .nav-pills {
    .nav-link {
      font-size: 1rem;
      padding: 0.4rem 0;
      border-radius: 0;
      margin-right: 2.5rem;
      color: #464646;

      &.active {
        background: none;
        color: #EE1C24;
        border-bottom: 3px solid #EE1C24;

        &.tradein {
          color: #24a8f2;
          border-bottom: 3px solid #24a8f2;
        }
      }
    }
  }

  hr.section-splitter {
    height: 6px;
    background: #f5f5f5;
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
  }

  .container {
    hr.section-splitter {
      margin: 0 -10px;
    }
  }

  .seller-contacts-inline {
    h6 {
      font-size: 0.8125rem;
      color: #000;
      font-weight: bold;
    }
    .list-group-item {
      &.with-text {
        border-bottom: 0;
        font-size: 0.8125rem;
        padding-bottom: 0.25rem;

        &:last-child {
          padding-bottom: 0.75rem;
        }

        .phone-number {
          display: block;
          color: inherit;
          font-size: 1rem;
          text-decoration: none;
        }

        a {
          color: inherit;
          text-decoration: underline;

          &.expand {
            color: #cccccc;
            text-decoration: underline;
            display: none;

            &.collapsed {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 400px) {
  .salon-section {
    .nav-pills {
      position: relative;

      li.nav-item {
        width: 33%;

        &:nth-child(2) {
          width: auto;
        }

        &:nth-child(3) {
          width: auto;
          right: 1rem;
          position: absolute;
        }

        a {
          margin-right: 0;
          background: none;
          display: inline-block;
        }
      }
    }
  }
}

@media (min-width: 401px) and (max-width: 440px) {
  .salon-section {
    .nav-pills {
      li.nav-item a {
        margin-right: 2rem;
      }
    }
  }
}


@media (min-width: 0px) and (max-width: 575px) {
  .salon-section {
    h1 {
      border: 0;
      font-size: 1.25rem;

      .vendor-item {
        transform: translateY(70%);
      }
    }

    .list-group-item {
      padding-left: 0;
      padding-right: 0;
      border: 0;
    }

    .container {
      .full-width {
        margin: 0 -15px;
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .salon-section {
    h1 {
      border: 0;
      position: relative;

      .vendor-img-lg[data-value] a {
        display: inline-block;
        position: relative;
        margin-top: -1rem;
        margin-bottom: -1rem;
        transform: translateY(-30%);
      }
    }

    .salon-list {
      padding: 0;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .salon-section {
    h1 {
      border: 0;
    }

    hr.section-splitter {
      /* 540px - ширина контейнера в этом размере сетки, 100vw - 100% ширины экрана, 1rem - отступы */
      left: calc((540px - 1rem - 100vw)/2);
      width: 100vw;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .salon-section {
    h1 {
      border-bottom: 0;
    }
    .popular-models {
      .car-item:nth-child(n+4) {
        display: none;
      }
    }

    hr.section-splitter {
      /* 720px - ширина контейнера в этом размере сетки, 100vw - 100% ширины экрана, 1rem - отступы */
      left: calc((720px - 1rem - 100vw) / 2);
      width: 100vw;
    }
  }
}

@media (min-width: 992px) {
  hr.section-splitter {
    display: none;
  }
  .salon-section {
    .popular-models {
      .car-item:nth-child(n+4) {
        display: none;
      }
    }
  }
}

@media (min-width: 1200px) {
  hr.section-splitter {
    display: none;
  }
  .salon-section {
  }
}
