.content {
  .img {
    width: 530px;
    overflow: hidden;
  }

  img.img {
    margin-top: 18px;
  }

  h2, h3 {
    margin: 36px 0 18px 0;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    margin: 18px 0 13px 0;
  }

  p {
    margin-bottom: 9px;
    font-size: 14px;
  }

  .quote, blockquote, q {
    width: 530px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 25px 0;
    font-size: 18px;
    line-height: 1.4;
    margin: 36px 0 36px 0;
  }

  h2, h3, h4, p, blockquote, .quote, q {
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}

@media (max-width: 575px) {
  .content {
    .quote, blockquote, q {
      width: auto;
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1800px) {
  .content {
    p {
      margin-bottom: 1.5vw;
      font-size: 1vw;
    }

    blockquote {
      font-size: 1.2vw;
    }
  }
}

@media screen and (min-width: 1800px) {
  .content {
    p {
      font-size: 16.2px;
      margin-bottom: 27px;

    }

    blockquote {
      font-size: 20.8px;
    }
  }
}