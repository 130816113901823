&[data-value] a:before { background-image: url(./../../../../../images/frontend/brands/sprites/32x32.png); width: 32px; height: 32px; background-position: -5416px 0px;}
&[data-value="acura"] a:before { background-position: 0px 0px; }
&[data-value="aito"] a:before { background-position: -52px 0px; }
&[data-value="alfa-romeo"] a:before { background-position: -104px 0px; }
&[data-value="ambertruck"] a:before { background-position: -156px 0px; }
&[data-value="aston-martin"] a:before { background-position: -208px 0px; }
&[data-value="audi"] a:before { background-position: -260px 0px; }
&[data-value="avant"] a:before { background-position: -312px 0px; }
&[data-value="baic"] a:before { background-position: -364px 0px; }
&[data-value="belarus"] a:before { background-position: -416px 0px; }
&[data-value="belgee"] a:before { background-position: -468px 0px; }
&[data-value="bentley"] a:before { background-position: -520px 0px; }
&[data-value="bestune"] a:before { background-position: -572px 0px; }
&[data-value="bmw"] a:before { background-position: -624px 0px; }
&[data-value="brilliance"] a:before { background-position: -676px 0px; }
&[data-value="bugatti"] a:before { background-position: -728px 0px; }
&[data-value="byd"] a:before { background-position: -780px 0px; }
&[data-value="cadillac"] a:before { background-position: -832px 0px; }
&[data-value="changan"] a:before { background-position: -884px 0px; }
&[data-value="chelyabinets"] a:before { background-position: -936px 0px; }
&[data-value="chery"] a:before { background-position: -988px 0px; }
&[data-value="chevrolet"] a:before { background-position: -1040px 0px; }
&[data-value="chrysler"] a:before { background-position: -1092px 0px; }
&[data-value="cimc"] a:before { background-position: -1144px 0px; }
&[data-value="citroen"] a:before { background-position: -1196px 0px; }
&[data-value="cukurova"] a:before { background-position: -1248px 0px; }
&[data-value="daewoo"] a:before { background-position: -1300px 0px; }
&[data-value="daf"] a:before { background-position: -1352px 0px; }
&[data-value="datsun"] a:before { background-position: -1404px 0px; }
&[data-value="dfm"] a:before { background-position: -1456px 0px; }
&[data-value="dfsk"] a:before { background-position: -1508px 0px; }
&[data-value="dodge"] a:before { background-position: -1560px 0px; }
&[data-value="dongfeng"] a:before { background-position: -1612px 0px; }
&[data-value="doosan"] a:before { background-position: -1664px 0px; }
&[data-value="evolute"] a:before { background-position: -1716px 0px; }
&[data-value="exeed"] a:before { background-position: -1768px 0px; }
&[data-value="faw"] a:before { background-position: -1812px 0px; }
&[data-value="ferrari"] a:before { background-position: -1864px 0px; }
&[data-value="fiat"] a:before { background-position: -1916px 0px; }
&[data-value="ford-truck"] a:before { background-position: -1968px 0px; }
&[data-value="ford"] a:before { background-position: -2020px 0px; }
&[data-value="forthing"] a:before { background-position: -2072px 0px; }
&[data-value="foton-lovol"] a:before { background-position: -2124px 0px; }
&[data-value="foton"] a:before { background-position: -2176px 0px; }
&[data-value="gac"] a:before { background-position: -2228px 0px; }
&[data-value="galichanin"] a:before { background-position: -2280px 0px; }
&[data-value="gaz"] a:before { background-position: -2332px 0px; }
&[data-value="geely"] a:before { background-position: -2384px 0px; }
&[data-value="genesis"] a:before { background-position: -2436px 0px; }
&[data-value="golden-dragon"] a:before { background-position: -2488px 0px; }
&[data-value="goodsense"] a:before { background-position: -2540px 0px; }
&[data-value="great-wall"] a:before { background-position: -2592px 0px; }
&[data-value="haima"] a:before { background-position: -2644px 0px; }
&[data-value="hangcha"] a:before { background-position: -2696px 0px; }
&[data-value="haval"] a:before { background-position: -2748px 0px; }
&[data-value="hawtai"] a:before { background-position: -2800px 0px; }
&[data-value="heli"] a:before { background-position: -2852px 0px; }
&[data-value="hino"] a:before { background-position: -2904px 0px; }
&[data-value="honda"] a:before { background-position: -2956px 0px; }
&[data-value="hongqi"] a:before { background-position: -3008px 0px; }
&[data-value="howo"] a:before { background-position: -3060px 0px; }
&[data-value="hyundai"] a:before { background-position: -3112px 0px; }
&[data-value="infiniti"] a:before { background-position: -3164px 0px; }
&[data-value="isuzu"] a:before { background-position: -3216px 0px; }
&[data-value="ivanovets"] a:before { background-position: -3268px 0px; }
&[data-value="iveco"] a:before { background-position: -3320px 0px; }
&[data-value="jac"] a:before { background-position: -3372px 0px; }
&[data-value="jaecoo"] a:before { background-position: -3424px 0px; }
&[data-value="jaguar"] a:before { background-position: -3476px 0px; }
&[data-value="jcb"] a:before { background-position: -3528px 0px; }
&[data-value="jeep"] a:before { background-position: -3596px 0px; }
&[data-value="jetour"] a:before { background-position: -3648px 0px; }
&[data-value="jetta"] a:before { background-position: -3700px 0px; }
&[data-value="jingong"] a:before { background-position: -3752px 0px; }
&[data-value="kaiyi"] a:before { background-position: -3804px 0px; }
&[data-value="kamaz"] a:before { background-position: -3856px 0px; }
&[data-value="kia"] a:before { background-position: -3908px 0px; }
&[data-value="klintsy"] a:before { background-position: -3960px 0px; }
&[data-value="knewstar"] a:before { background-position: -4012px 0px; }
&[data-value="komatsu"] a:before { background-position: -4064px 0px; }
&[data-value="kraz"] a:before { background-position: -4116px 0px; }
&[data-value="lada"] a:before { background-position: -4168px 0px; }
&[data-value="lamborghini"] a:before { background-position: -4220px 0px; }
&[data-value="land-rover"] a:before { background-position: -4272px 0px; }
&[data-value="lexus"] a:before { background-position: -4324px 0px; }
&[data-value="liaz"] a:before { background-position: -4376px 0px; }
&[data-value="lifan"] a:before { background-position: -4428px 0px; }
&[data-value="lincoln"] a:before { background-position: -4480px 0px; }
&[data-value="liugong"] a:before { background-position: -4532px 0px; }
&[data-value="livan"] a:before { background-position: -4584px 0px; }
&[data-value="lonking"] a:before { background-position: -4636px 0px; }
&[data-value="lotus"] a:before { background-position: -4688px 0px; }
&[data-value="m-hero"] a:before { background-position: -4740px 0px; }
&[data-value="man"] a:before { background-position: -4792px 0px; }
&[data-value="maserati"] a:before { background-position: -4844px 0px; }
&[data-value="maybach"] a:before { background-position: -4896px 0px; }
&[data-value="maz"] a:before { background-position: -4948px 0px; }
&[data-value="mazda"] a:before { background-position: -5000px 0px; }
&[data-value="mercedes-benz"] a:before { background-position: -5052px 0px; }
&[data-value="mg"] a:before { background-position: -5104px 0px; }
&[data-value="mini"] a:before { background-position: -5156px 0px; }
&[data-value="mitsubishi"] a:before { background-position: -5208px 0px; }
&[data-value="moskvich"] a:before { background-position: -5260px 0px; }
&[data-value="nefaz"] a:before { background-position: -5312px 0px; }
&[data-value="nissan"] a:before { background-position: -5364px 0px; }
&[data-value="no-brand"] a:before { background-position: -5416px 0px; }
&[data-value="omoda"] a:before { background-position: -5468px 0px; }
&[data-value="opel"] a:before { background-position: -5520px 0px; }
&[data-value="ora"] a:before { background-position: -5572px 0px; }
&[data-value="oting"] a:before { background-position: -5624px 0px; }
&[data-value="palfinger"] a:before { background-position: -5676px 0px; }
&[data-value="paz"] a:before { background-position: -5728px 0px; }
&[data-value="peugeot"] a:before { background-position: -5780px 0px; }
&[data-value="pontiac"] a:before { background-position: -5832px 0px; }
&[data-value="porsche"] a:before { background-position: -5884px 0px; }
&[data-value="ram"] a:before { background-position: -5936px 0px; }
&[data-value="ravon"] a:before { background-position: -5988px 0px; }
&[data-value="renault"] a:before { background-position: -6040px 0px; }
&[data-value="rolls-royce"] a:before { background-position: -6092px 0px; }
&[data-value="rongwei"] a:before { background-position: -6144px 0px; }
&[data-value="rox"] a:before { background-position: -6196px 0px; }
&[data-value="saab"] a:before { background-position: -6248px 0px; }
&[data-value="samsung"] a:before { background-position: -6300px 0px; }
&[data-value="scania"] a:before { background-position: -6352px 0px; }
&[data-value="schmitz"] a:before { background-position: -6404px 0px; }
&[data-value="scion"] a:before { background-position: -6456px 0px; }
&[data-value="seat"] a:before { background-position: -6508px 0px; }
&[data-value="shacman"] a:before { background-position: -6560px 0px; }
&[data-value="shantui"] a:before { background-position: -6612px 0px; }
&[data-value="skoda"] a:before { background-position: -6664px 0px; }
&[data-value="skywell"] a:before { background-position: -6716px 0px; }
&[data-value="smart"] a:before { background-position: -6768px 0px; }
&[data-value="solaris"] a:before { background-position: -6820px 0px; }
&[data-value="sollers"] a:before { background-position: -6872px 0px; }
&[data-value="soueast"] a:before { background-position: -6924px 0px; }
&[data-value="ssangyong"] a:before { background-position: -6976px 0px; }
&[data-value="subaru"] a:before { background-position: -7028px 0px; }
&[data-value="sunward"] a:before { background-position: -7080px 0px; }
&[data-value="suzuki"] a:before { background-position: -7132px 0px; }
&[data-value="swm"] a:before { background-position: -7184px 0px; }
&[data-value="tank"] a:before { background-position: -7236px 0px; }
&[data-value="tesla"] a:before { background-position: -7288px 0px; }
&[data-value="toyota"] a:before { background-position: -7340px 0px; }
&[data-value="tveks"] a:before { background-position: -7392px 0px; }
&[data-value="uaz"] a:before { background-position: -7444px 0px; }
&[data-value="venucia"] a:before { background-position: -7496px 0px; }
&[data-value="vgv"] a:before { background-position: -7548px 0px; }
&[data-value="volkswagen"] a:before { background-position: -7600px 0px; }
&[data-value="volvo"] a:before { background-position: -7652px 0px; }
&[data-value="voyah"] a:before { background-position: -7704px 0px; }
&[data-value="wecan"] a:before { background-position: -7756px 0px; }
&[data-value="wey"] a:before { background-position: -7808px 0px; }
&[data-value="xcite"] a:before { background-position: -7860px 0px; }
&[data-value="xcmg"] a:before { background-position: -7912px 0px; }
&[data-value="zaz"] a:before { background-position: -7964px 0px; }
&[data-value="zeekr"] a:before { background-position: -8016px 0px; }
&[data-value="zoomlion"] a:before { background-position: -8068px 0px; }
&[data-value="zotye"] a:before { background-position: -8120px 0px; }