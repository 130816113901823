.bg-dark {
  .breadcrumbs-line {
    a {
      color: #999999;
    }
    color: #999999;
  }
}

.breadcrumbs-line {
  a {
    color: #464646;
    text-decoration: underline;

    &:hover {
      color: #EE1C24;
      text-decoration: none;
    }
  }
}

.open-car {
  .breadcrumbs-line {
    padding-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .open-car {
    .breadcrumbs-line {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}