&[data-value] a:before { background-image: url(./../../../../../images/frontend/brands/sprites/24x24.png); width: 24px; height: 24px; background-position: -4576px 0px;}
&[data-value="acura"] a:before { background-position: 0px 0px; }
&[data-value="aito"] a:before { background-position: -44px 0px; }
&[data-value="alfa-romeo"] a:before { background-position: -88px 0px; }
&[data-value="ambertruck"] a:before { background-position: -132px 0px; }
&[data-value="aston-martin"] a:before { background-position: -176px 0px; }
&[data-value="audi"] a:before { background-position: -220px 0px; }
&[data-value="avant"] a:before { background-position: -264px 0px; }
&[data-value="baic"] a:before { background-position: -308px 0px; }
&[data-value="belarus"] a:before { background-position: -352px 0px; }
&[data-value="belgee"] a:before { background-position: -396px 0px; }
&[data-value="bentley"] a:before { background-position: -440px 0px; }
&[data-value="bestune"] a:before { background-position: -484px 0px; }
&[data-value="bmw"] a:before { background-position: -528px 0px; }
&[data-value="brilliance"] a:before { background-position: -572px 0px; }
&[data-value="bugatti"] a:before { background-position: -616px 0px; }
&[data-value="byd"] a:before { background-position: -660px 0px; }
&[data-value="cadillac"] a:before { background-position: -704px 0px; }
&[data-value="changan"] a:before { background-position: -748px 0px; }
&[data-value="chelyabinets"] a:before { background-position: -792px 0px; }
&[data-value="chery"] a:before { background-position: -836px 0px; }
&[data-value="chevrolet"] a:before { background-position: -880px 0px; }
&[data-value="chrysler"] a:before { background-position: -924px 0px; }
&[data-value="cimc"] a:before { background-position: -968px 0px; }
&[data-value="citroen"] a:before { background-position: -1012px 0px; }
&[data-value="cukurova"] a:before { background-position: -1056px 0px; }
&[data-value="daewoo"] a:before { background-position: -1100px 0px; }
&[data-value="daf"] a:before { background-position: -1144px 0px; }
&[data-value="datsun"] a:before { background-position: -1188px 0px; }
&[data-value="dfm"] a:before { background-position: -1232px 0px; }
&[data-value="dfsk"] a:before { background-position: -1276px 0px; }
&[data-value="dodge"] a:before { background-position: -1320px 0px; }
&[data-value="dongfeng"] a:before { background-position: -1364px 0px; }
&[data-value="doosan"] a:before { background-position: -1408px 0px; }
&[data-value="evolute"] a:before { background-position: -1452px 0px; }
&[data-value="exeed"] a:before { background-position: -1496px 0px; }
&[data-value="faw"] a:before { background-position: -1540px 0px; }
&[data-value="ferrari"] a:before { background-position: -1584px 0px; }
&[data-value="fiat"] a:before { background-position: -1628px 0px; }
&[data-value="ford-truck"] a:before { background-position: -1672px 0px; }
&[data-value="ford"] a:before { background-position: -1716px 0px; }
&[data-value="forthing"] a:before { background-position: -1760px 0px; }
&[data-value="foton-lovol"] a:before { background-position: -1804px 0px; }
&[data-value="foton"] a:before { background-position: -1848px 0px; }
&[data-value="gac"] a:before { background-position: -1892px 0px; }
&[data-value="galichanin"] a:before { background-position: -1936px 0px; }
&[data-value="gaz"] a:before { background-position: -1980px 0px; }
&[data-value="geely"] a:before { background-position: -2024px 0px; }
&[data-value="genesis"] a:before { background-position: -2068px 0px; }
&[data-value="golden-dragon"] a:before { background-position: -2112px 0px; }
&[data-value="goodsense"] a:before { background-position: -2156px 0px; }
&[data-value="great-wall"] a:before { background-position: -2200px 0px; }
&[data-value="haima"] a:before { background-position: -2244px 0px; }
&[data-value="hangcha"] a:before { background-position: -2288px 0px; }
&[data-value="haval"] a:before { background-position: -2332px 0px; }
&[data-value="hawtai"] a:before { background-position: -2376px 0px; }
&[data-value="heli"] a:before { background-position: -2420px 0px; }
&[data-value="hino"] a:before { background-position: -2464px 0px; }
&[data-value="honda"] a:before { background-position: -2508px 0px; }
&[data-value="hongqi"] a:before { background-position: -2552px 0px; }
&[data-value="howo"] a:before { background-position: -2596px 0px; }
&[data-value="hyundai"] a:before { background-position: -2640px 0px; }
&[data-value="infiniti"] a:before { background-position: -2684px 0px; }
&[data-value="isuzu"] a:before { background-position: -2728px 0px; }
&[data-value="ivanovets"] a:before { background-position: -2772px 0px; }
&[data-value="iveco"] a:before { background-position: -2816px 0px; }
&[data-value="jac"] a:before { background-position: -2860px 0px; }
&[data-value="jaecoo"] a:before { background-position: -2904px 0px; }
&[data-value="jaguar"] a:before { background-position: -2948px 0px; }
&[data-value="jcb"] a:before { background-position: -2992px 0px; }
&[data-value="jeep"] a:before { background-position: -3036px 0px; }
&[data-value="jetour"] a:before { background-position: -3080px 0px; }
&[data-value="jetta"] a:before { background-position: -3124px 0px; }
&[data-value="jingong"] a:before { background-position: -3168px 0px; }
&[data-value="kaiyi"] a:before { background-position: -3212px 0px; }
&[data-value="kamaz"] a:before { background-position: -3256px 0px; }
&[data-value="kia"] a:before { background-position: -3300px 0px; }
&[data-value="klintsy"] a:before { background-position: -3344px 0px; }
&[data-value="knewstar"] a:before { background-position: -3388px 0px; }
&[data-value="komatsu"] a:before { background-position: -3432px 0px; }
&[data-value="kraz"] a:before { background-position: -3476px 0px; }
&[data-value="lada"] a:before { background-position: -3520px 0px; }
&[data-value="lamborghini"] a:before { background-position: -3564px 0px; }
&[data-value="land-rover"] a:before { background-position: -3608px 0px; }
&[data-value="lexus"] a:before { background-position: -3652px 0px; }
&[data-value="liaz"] a:before { background-position: -3696px 0px; }
&[data-value="lifan"] a:before { background-position: -3740px 0px; }
&[data-value="lincoln"] a:before { background-position: -3784px 0px; }
&[data-value="liugong"] a:before { background-position: -3828px 0px; }
&[data-value="livan"] a:before { background-position: -3872px 0px; }
&[data-value="lonking"] a:before { background-position: -3916px 0px; }
&[data-value="lotus"] a:before { background-position: -3960px 0px; }
&[data-value="m-hero"] a:before { background-position: -4004px 0px; }
&[data-value="man"] a:before { background-position: -4048px 0px; }
&[data-value="maserati"] a:before { background-position: -4092px 0px; }
&[data-value="maybach"] a:before { background-position: -4136px 0px; }
&[data-value="maz"] a:before { background-position: -4180px 0px; }
&[data-value="mazda"] a:before { background-position: -4224px 0px; }
&[data-value="mercedes-benz"] a:before { background-position: -4268px 0px; }
&[data-value="mg"] a:before { background-position: -4312px 0px; }
&[data-value="mini"] a:before { background-position: -4356px 0px; }
&[data-value="mitsubishi"] a:before { background-position: -4400px 0px; }
&[data-value="moskvich"] a:before { background-position: -4444px 0px; }
&[data-value="nefaz"] a:before { background-position: -4488px 0px; }
&[data-value="nissan"] a:before { background-position: -4532px 0px; }
&[data-value="no-brand"] a:before { background-position: -4576px 0px; }
&[data-value="omoda"] a:before { background-position: -4620px 0px; }
&[data-value="opel"] a:before { background-position: -4664px 0px; }
&[data-value="ora"] a:before { background-position: -4708px 0px; }
&[data-value="oting"] a:before { background-position: -4752px 0px; }
&[data-value="palfinger"] a:before { background-position: -4796px 0px; }
&[data-value="paz"] a:before { background-position: -4840px 0px; }
&[data-value="peugeot"] a:before { background-position: -4884px 0px; }
&[data-value="pontiac"] a:before { background-position: -4928px 0px; }
&[data-value="porsche"] a:before { background-position: -4972px 0px; }
&[data-value="ram"] a:before { background-position: -5016px 0px; }
&[data-value="ravon"] a:before { background-position: -5060px 0px; }
&[data-value="renault"] a:before { background-position: -5104px 0px; }
&[data-value="rolls-royce"] a:before { background-position: -5148px 0px; }
&[data-value="rongwei"] a:before { background-position: -5192px 0px; }
&[data-value="rox"] a:before { background-position: -5236px 0px; }
&[data-value="saab"] a:before { background-position: -5280px 0px; }
&[data-value="samsung"] a:before { background-position: -5324px 0px; }
&[data-value="scania"] a:before { background-position: -5368px 0px; }
&[data-value="schmitz"] a:before { background-position: -5412px 0px; }
&[data-value="scion"] a:before { background-position: -5456px 0px; }
&[data-value="seat"] a:before { background-position: -5500px 0px; }
&[data-value="shacman"] a:before { background-position: -5544px 0px; }
&[data-value="shantui"] a:before { background-position: -5588px 0px; }
&[data-value="skoda"] a:before { background-position: -5632px 0px; }
&[data-value="skywell"] a:before { background-position: -5676px 0px; }
&[data-value="smart"] a:before { background-position: -5720px 0px; }
&[data-value="solaris"] a:before { background-position: -5764px 0px; }
&[data-value="sollers"] a:before { background-position: -5808px 0px; }
&[data-value="soueast"] a:before { background-position: -5852px 0px; }
&[data-value="ssangyong"] a:before { background-position: -5896px 0px; }
&[data-value="subaru"] a:before { background-position: -5940px 0px; }
&[data-value="sunward"] a:before { background-position: -5984px 0px; }
&[data-value="suzuki"] a:before { background-position: -6028px 0px; }
&[data-value="swm"] a:before { background-position: -6072px 0px; }
&[data-value="tank"] a:before { background-position: -6116px 0px; }
&[data-value="tesla"] a:before { background-position: -6160px 0px; }
&[data-value="toyota"] a:before { background-position: -6204px 0px; }
&[data-value="tveks"] a:before { background-position: -6248px 0px; }
&[data-value="uaz"] a:before { background-position: -6292px 0px; }
&[data-value="venucia"] a:before { background-position: -6336px 0px; }
&[data-value="vgv"] a:before { background-position: -6380px 0px; }
&[data-value="volkswagen"] a:before { background-position: -6424px 0px; }
&[data-value="volvo"] a:before { background-position: -6468px 0px; }
&[data-value="voyah"] a:before { background-position: -6512px 0px; }
&[data-value="wecan"] a:before { background-position: -6556px 0px; }
&[data-value="wey"] a:before { background-position: -6600px 0px; }
&[data-value="xcite"] a:before { background-position: -6644px 0px; }
&[data-value="xcmg"] a:before { background-position: -6688px 0px; }
&[data-value="zaz"] a:before { background-position: -6732px 0px; }
&[data-value="zeekr"] a:before { background-position: -6776px 0px; }
&[data-value="zoomlion"] a:before { background-position: -6820px 0px; }
&[data-value="zotye"] a:before { background-position: -6864px 0px; }