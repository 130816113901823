/*
 * Переменные для расчета отступа от правого края, кнопки 'наверх':
 * $width-section    - ширина блока section (основной контентный блок)
 * $width-button-up  - ширина кнопки 'наверх'
 * $height-button-up - высота кнопки 'наверх'
 *
 * Расчет отступа от правого края (необходим, чтобы динамически задавалось значение, для разных разрешений экрана):
 * (ширина_окна_браузера - ширина_блока_с_контентом(section)) / 2 - ширина_кнопки_наверх
 */

$width-section: 1238px;
$width-button-up: 60px;
$height-button-up: $width-button-up;
$button-up-right-indent: calc((100% - #{$width-section}) / 2 - #{$width-button-up});

.back-to-top, .share-button {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 24px;
  color: #EE1C24;
  display: none;
  width: $width-button-up;
  height: $height-button-up;
  text-align: center;
  line-height: 3.75rem;
  background: #EE1C24 url(../../../images/frontend/icons/top-icon.png) no-repeat center;
  background-size: contain;
  z-index: 50;

  @media screen and (min-width: 1340px) {
    right: $button-up-right-indent;
  }

  @media screen and (max-width: 991px) {
    width: calc(#{$width-button-up} * 0.75);
    height: calc(#{$height-button-up} * 0.75);
    background-size: calc(#{$width-button-up} * 0.75) calc(#{$height-button-up} * 0.75);
    bottom: 90px;
  }
}

.share-button {
  bottom: 140px;
  display: block;
  background: #F5F5F5 url(../../../images/frontend/icons/share-android.svg) no-repeat center;

  @media screen and (max-width: 991px) {
    bottom: calc(77px + #{$width-button-up} * 0.75 * 2);
    background-size: calc(#{$width-button-up} * 0.75) calc(#{$height-button-up} * 0.75);
  }

  @supports (-webkit-touch-callout: none) {
    background: #F5F5F5 url(../../../images/frontend/icons/share-ios.svg) no-repeat center;
  }

  .ya-share2__title {
    display: none;
  }

  .ya-share2__badge {
    border-radius: 0;
  }

  .ya-share2__item_more .ya-share2__icon_more {
    background: none;
  }

  .ya-share2__item_more .ya-share2__badge_more {
    background: none;
    height: 60px;
    width: 60px;
    @media screen and (max-width: 991px) {
      width: calc(#{$width-button-up} * 0.75);
      height: calc(#{$height-button-up} * 0.75);
    }
  }
}