.header-cars {
  .hiden-long-text {
    overflow: hidden;
    position: relative;
    height: 54px;
    cursor: pointer;

    &:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 1.2em;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%);
      pointer-events: none;
    }

    a {
      pointer-events: none;
    }
  }
}