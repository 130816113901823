.seller-show-sidebar {
  top: 1rem;

  .list-group {
    border-radius: 0;
  }

  a.list-group-item {
    font-size: 1rem;
    &:before {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      transform: translateY(-50%);
      content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.589966 10.59L5.16997 6L0.589966 1.41L1.99997 0L7.99997 6L1.99997 12L0.589966 10.59Z' fill='%23999999'/%3E%3C/svg%3E");
    }

    &.active:before {
      content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.589966 10.59L5.16997 6L0.589966 1.41L1.99997 0L7.99997 6L1.99997 12L0.589966 10.59Z' fill='%23EE1C24'/%3E%3C/svg%3E");
    }

    &.active.tradein:before {
      content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.589966 10.59L5.16997 6L0.589966 1.41L1.99997 0L7.99997 6L1.99997 12L0.589966 10.59Z' fill='%231178C6'/%3E%3C/svg%3E");
    }
  }

  .list-group-item {
    border-bottom: 0;
    color: #464646;
    border-color: #dedede;

    & + .list-group-item.active {
      margin-top: 0;
      border-top-width: 0;
    }

    &.active {
      background: none;
      color: #ee1c25;

      border-color: #dedede;

      &.tradein {
        color: #007bff;
      }
    }

    &.with-line:after {
      content: " ";
      display: block;
      height: 1px;
      background: #ececec;
      position: relative;
      top: 0.75rem;
    }

    &:last-child {
      border-bottom: 1px solid #ececec;
    }

    &.with-text {
      padding-left: 2.25rem;
      padding-right: 1rem;
      font-size: 0.8125rem;
      padding-bottom: 0.25rem;

      &:last-child {
        padding-bottom: 0.75rem;
      }

      .phone-number {
        color: inherit;
        font-size: 1rem;
        text-decoration: underline;

        &:hover {
          color: #0056b3 !important;

          .code {
            color: #0056b3 !important;
          }
        }
      }

      a {
        display: block;
        color: inherit;
        text-decoration: underline;

        &.scrollto {
          color: #ccc;
        }

        &.expand {
          display: block;
          color: #ccc;
          text-decoration: underline;
          display: none;

          &.collapsed {
            display: block;
          }
        }
      }
    }
  }

  h6 {
    font-size: 0.8125rem;
    font-weight: bold;
  }
}

.salon-section {
  .btn-primary {
    background-color: #23a8f2;
    border-color: #23a8f2;
  }
}
