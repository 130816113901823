.navbar-toggler {
  padding: 2px 4px;
  font-size: 0.75rem;

  &:focus {
    outline: none;
  }
}

header {
  border-bottom: 5px solid #ee1c24;
  background: #f5f5f5;

  .main-menu-navbar {
    height: 68px;
    background: #fff;

    &:after {
      content: " ";
      width: 100%;
      height: 10px;
      position: absolute;
      z-index: 103;
      background: -moz-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 100%);
      background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 100%);
      background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
      left: 0;
      bottom: -10px;
    }

    .logo-navbar-nav {
      font-size: 1.25rem;
      color: #464646;
      /*чтобы Усть-каменогорск не улетал в космос*/
      margin-right: -3rem;

      .nav-item {
        line-height: 1.3rem;
      }

      a {
        color: #464646;
        text-decoration: underline;

        &:hover {
          color: #ee1c24;
          text-decoration: none;
        }
      }

      .fas {
        font-size: 0.8rem;
        color: #ee1c24;
      }

      small {
        font-size: 0.75rem;
        color: #999;
      }
    }

    .main-menu-list {
      a {
        font-size: 0.875rem;
        text-transform: uppercase;
        text-decoration: none;
        color: #464646;

        &:hover {
          text-decoration: underline;
        }

        small {
          font-size: 0.75rem;
          color: #999;
          display: inline-block;
          text-transform: none;
        }
      }

      .nav-item {
        height: 65px;
        position: relative;
        display: flex;
        align-items: center !important;

        a {
          border-left: 1px solid #e0e0e0;
        }

        &.active {
          a {
            color: #ee1c25;
            border-left: 1px solid #ee1d23;

            &:hover {
              text-decoration: none;
            }
          }

          small {
            color: #464646;
          }

          &:after {
            content: " ";
            display: block;
            width: 100%;
            height: 3px;
            background: #ee1d23;
            position: absolute;
            bottom: -1px;
          }

          &:last-child {
            a {
              border-right: 1px solid #ee1d23;
            }
          }
        }

        &:last-child {
          padding-right: 0;
        }

      }
      .nav-item.active + .nav-item {
        a {
          border-left: 1px solid #ee1d23;
        }
      }
    }
  }

  .banner-wrapper {
    padding: 10px 0;
    background-color: #F5F5F5;
  }

  img.img-fluid {
    max-width: 50px;
  }
}

@media (max-width: 991px) {
  header {
    .main-menu-navbar .main-menu-list {
      position: absolute;
      background: #f5f5f5;
      width: 30rem;
      top: 9px;
      right: -1rem;
      border: 1px solid #ccc;
      border-right: 0;
      border-top: 0;
      border-radius: 0 0 0 16px;
      z-index: 102;

      .nav-item {
        height: 5rem;

        a {
          text-decoration: none;
          border: none;
          line-height: 1.8rem;

          &:after {
            content: " ";
            display: block;
            position: absolute;
            height: 1px;
            background: #ccc;
            margin-top: 0.5rem;
            bottom: 0;
            left: 1rem;
            right: 1rem;
          }

          small {
            position: relative;
            top: -7px;
            line-height: 1rem;
          }
        }

        i.fas {
          font-size: 1.5rem;
          color: #999;
          line-height: 2.5rem;
          margin-top: 5px !important;
        }

        &.active {
          border: none;
          a {
            border: none;

            &:last-child {
              a {
                border-right: 1px solid #ee1d23;
              }
            }
          }
          &:after {
            display: none;
          }
        }

        .fa-cars {
          content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 32'><g><g><path fill='rgb(153, 153, 153)' d='M51.21,7.32h-4L46.15,4.6A7.39,7.39,0,0,0,39.27,0h-8.4A7.38,7.38,0,0,0,24,4.6l-.37.92a10.17,10.17,0,0,1,3.81,1.85l.49-1.21a3.17,3.17,0,0,1,2.95-2h8.4a3.18,3.18,0,0,1,2.95,2l1.31,3.26H29.4a9.62,9.62,0,0,1,1.15,2.09l.42,1h2.1a3.52,3.52,0,0,1,2.77,1.32,3.36,3.36,0,0,1,.65,3L36.1,18.4a3.41,3.41,0,0,1-.77,1.44,6.68,6.68,0,0,1,.17,1.09h8V23a2.1,2.1,0,0,0,2.12,2.09h2.12A2.09,2.09,0,0,0,49.88,23V19.48a4.1,4.1,0,0,0,1.06-2.74V13.6a4.15,4.15,0,0,0-1.46-3.14h1.33a.79.79,0,0,0,.77-.59L52,8.3A.79.79,0,0,0,51.21,7.32Zm-5.56,9.41c-1.27,0-3.17.21-3.17-1a4,4,0,0,1,3.17-3.13,2,2,0,0,1,2.12,2.08A2,2,0,0,1,45.65,16.73Z'/><path fill='rgb(153, 153, 153)' d='M33.07,15.21h-4L28,12.49a7.37,7.37,0,0,0-6.88-4.6h-8.4a7.36,7.36,0,0,0-6.88,4.6l-1.1,2.72h-4a.79.79,0,0,0-.77,1l.4,1.57a.79.79,0,0,0,.77.59H2.52a4.13,4.13,0,0,0-1.46,3.14v3.14a4.14,4.14,0,0,0,1.06,2.74v3.54A2.1,2.1,0,0,0,4.23,33H6.35a2.1,2.1,0,0,0,2.11-2.09v-2.1H25.39v2.1A2.11,2.11,0,0,0,27.51,33h2.12a2.1,2.1,0,0,0,2.11-2.09V27.37a4.12,4.12,0,0,0,1.06-2.74V21.49a4.13,4.13,0,0,0-1.46-3.14h1.33a.79.79,0,0,0,.77-.59l.4-1.57A.79.79,0,0,0,33.07,15.21ZM9.78,14.05a3.17,3.17,0,0,1,3-2h8.4a3.18,3.18,0,0,1,2.95,2l1.31,3.25H8.46ZM6.35,24.62a2.09,2.09,0,1,1,0-4.18,4,4,0,0,1,3.17,3.13C9.52,24.82,7.62,24.62,6.35,24.62Zm21.16,0c-1.27,0-3.17.2-3.17-1.05a4,4,0,0,1,3.17-3.13,2.09,2.09,0,1,1,0,4.18Z'/></g></g></svg>");
          height: 40px;
          width: 36px;
          margin-right: 12px !important;
        }

        .fa-map-marker-alt {
          width: 30px;
        }

        &:last-child {
          a {
            &:after {
              display: none;
            }
          }
        }
      }

      .nav-item.active + .nav-item {
        a {
          border-left: none;
        }
      }
    }

    .navbar-light .navbar-toggler-icon {
      background-size: 44px 44px;
      width: 3rem;
      height: 3rem;
      background-image: url("data:image/svg+xml,<svg viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'><path stroke='rgb(153, 153, 153)' stroke-width='7' stroke-linecap='square' stroke-miterlimit='10' d='M10 14h40M10 29h40M10 44h40'/></svg>");
    }

    .navbar-light .navbar-toggler {
      border: none;
    }
  }
}

@media (min-width: 992px) {
  header {
    img.img-fluid {
      width: 50px;
      max-width: 50px;
      height: 50px;
      margin-right: 15px;
    }

    .main-menu-list ul {
      flex-wrap: initial;
    }

    .main-menu-list {
      .nav-item {
        &:last-child {
          a {
            border-right: 1px solid #e0e0e0;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  header {
    .main-menu-navbar {
      height: 78px;
      .main-menu-list {
        width: 15rem;
      }
    }

    .main-menu-navbar .main-menu-list {
      overflow: hidden;

      .nav-item {
        &.active {
          a:after {
            border-bottom: 1px solid #ccc;
          }
          &:after {
            display: block;
            height: 100%;
            width: 18px;
            border-radius: 26px;
            right: -13px;
            top: 0px;
            border: none;
            clip-path: inset(0px 12px 0px 0px);
          }
        }
      }
    }

    .logo-navbar-nav {
      a {
        white-space: nowrap;
      }

      .nav-item {
        font-size: 5vmin;
        text-align: center;
      }

      .nav-item * {
        font-size: 4vmin;
      }

      small {
        display: none;
      }
    }

    .navbar-light .navbar-toggler-icon {
      background-size: 12vmin 12vmin;
      width: 14vmin;
      height: 14vmin;
    }
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  header {
    .main-menu-navbar .main-menu-list {
      .nav-item {
        &.active {
          &:last-child a {
            border-right: none;
          }

          &:after {
            display: block;
            height: 100%;
            width: 18px;
            border-radius: 26px;
            right: -13px;
            top: 0px;
            border: none;
            clip-path: inset(0px 12px 0px 0px);
          }

          &:nth-child(odd) {
            &:after {
              left: -12px;
              top: 0px;
              border: none;
              clip-path: inset(0px 0px 0px 12px);
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  header span.d-lg-none.d-xl-inline {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  header .main-menu-navbar .logo-navbar-nav {
    font-size: 1rem;
  }
}

@media (min-width: 576px) {
  .nav-item-location {
    display: none !important;
  }

  .main-menu-navbar {
    .logo-navbar-nav {
      .fa-sync-alt {
        display: none;
      }
    }
  }
}

@media (max-width: 575px) {
  .nav-item-location {
    a {
      span {
        line-height: 53px;
      }

      .fa-sync-alt {
        color: #EE1C24 !important;
        margin-top: unset !important;
        margin-left: 10px;
        font-size: 0.75rem !important;
      }
    }
  }

  .main-menu-navbar {
    .logo-navbar-nav {
      .fa-sync-alt {
        margin-left: 8px;
      }
    }
  }
}
