#location-is-correct-modal {
  color: #999;

  h2 {
    font-size: 1rem;
    font-weight: bold;
  }

  .modal-dialog {
    max-width: 17rem;
  }
}

.modal-buy-online {
  .error {
    border-color: red;
  }
}

.menu-city-wrapper {
  background-color: #464646;

  .menu-city-inner {
    position: relative;
    background-color: #fff;

    h2 {
      font-size: 1.1rem;
      font-weight: bold;
      line-height: 1.9rem;

      .in-city {
        background: #EE1C24;
        border-radius: 3px;
        padding: 0.5rem;
        color: #fff;
        font-weight: normal;
      }
    }

    .subtitle {
      font-size: 0.9rem;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }

    .menu-city-search {
      label {
        font-size: 0.875rem;
        width: 2.5rem;
      }

      input {
        height: 1.875rem;
        background-repeat: no-repeat;
        font-size: 0.75rem;
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjM5M3B0IiB2aWV3Qm94PSItNCAwIDM5MyAzOTMuOTkwMDMiIHdpZHRoPSIzOTNwdCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBzdHlsZT0iZmlsbDogIzgwODA4MCIgZD0ibTM2OC4zMTI1IDBoLTM1MS4yNjE3MTljLTYuMTk1MzEyLS4wMTE3MTg4LTExLjg3NSAzLjQ0OTIxOS0xNC43MDcwMzEgOC45NjA5MzgtMi44NzEwOTQgNS41ODU5MzctMi4zNjcxODc1IDEyLjMxMjUgMS4zMDA3ODEgMTcuNDE0MDYybDEyOC42ODc1IDE4MS4yODEyNWMuMDQyOTY5LjA2MjUuMDg5ODQ0LjEyMTA5NC4xMzI4MTMuMTgzNTk0IDQuNjc1NzgxIDYuMzEyNSA3LjIwMzEyNSAxMy45NTcwMzEgNy4yMTg3NSAyMS44MTY0MDZ2MTQ3Ljc5Njg3NWMtLjAyNzM0NCA0LjM3ODkwNiAxLjY5MTQwNiA4LjU4MjAzMSA0Ljc3NzM0NCAxMS42ODc1IDMuMDg1OTM3IDMuMTA1NDY5IDcuMjgxMjUgNC44NDc2NTYgMTEuNjU2MjUgNC44NDc2NTYgMi4yMjY1NjIgMCA0LjQyNTc4MS0uNDQ1MzEyIDYuNDgwNDY4LTEuMjk2ODc1bDcyLjMxMjUtMjcuNTc0MjE4YzYuNDgwNDY5LTEuOTc2NTYzIDEwLjc4MTI1LTguMDg5ODQ0IDEwLjc4MTI1LTE1LjQ1MzEyNnYtMTIwLjAwNzgxMmMuMDExNzE5LTcuODU1NDY5IDIuNTQyOTY5LTE1LjUwMzkwNiA3LjIxNDg0NC0yMS44MTY0MDYuMDQyOTY5LS4wNjI1LjA4OTg0NC0uMTIxMDk0LjEzMjgxMi0uMTgzNTk0bDEyOC42ODM1OTQtMTgxLjI4OTA2MmMzLjY2Nzk2OS01LjA5NzY1NyA0LjE3MTg3NS0xMS44MjAzMTMgMS4zMDA3ODItMTcuNDA2MjUtMi44MzIwMzItNS41MTE3MTktOC41MTE3MTktOC45NzI2NTY4LTE0LjcxMDkzOC04Ljk2MDkzOHptLTEzMS41MzEyNSAxOTUuOTkyMTg4Yy03LjE4NzUgOS43NTM5MDYtMTEuMDc0MjE5IDIxLjU0Njg3NC0xMS4wOTc2NTYgMzMuNjY0MDYydjExNy41NzgxMjVsLTY2IDI1LjE2NDA2M3YtMTQyLjc0MjE4OGMtLjAyMzQzOC0xMi4xMTcxODgtMy45MTAxNTYtMjMuOTEwMTU2LTExLjEwMTU2My0zMy42NjQwNjJsLTEyNC45MzM1OTMtMTc1Ljk5MjE4OGgzMzguMDcwMzEyem0wIDAiLz48L3N2Zz4=");
        background-position: right 0.5rem center;
        background-size: 1.25rem 1.25rem;
        @include border-radius($input-border-radius, 0);
      }
    }

    .list-cities-header {
      border-bottom: 1px solid #e0e0e0;
    }

    .list-cities-show-all {
      text-align: center;

      a {
        &:link, &:visited {
          color: #999;
          text-decoration: none;
        }

        &:after {
          content: url('../../../images/frontend/arrow-down.png');
          display: block;
          position: absolute;
          right: -10px;
          top: -1px;
        }

        &.collapsed {
          &:link, &:visited {
            color: #fff;
          }
        }
      }
    }

    .menu-city-list {
      clear: both;

      &.available {
        -moz-column-count: 5;
        -moz-column-gap: 1rem;
        -webkit-column-count: 5;
        -webkit-column-gap: 1rem;
        column-count: 5;
        column-gap: 1rem;
      }

      li {
        position: relative;
        display: inline-block;
        width: 100%;

        &.selected {
          a {
            &:link, &:visited {
              background: #EE1C24;
              border-radius: 3px;
              color: #fff;
            }

            &:hover {
              span {
                text-decoration: none;
              }
            }

            sup {
              color: #fff;
            }

            .highlight {
              color: black;
            }
          }
        }

        a {
          display: block;

          &:link,
          &:visited {
            color: #464646;
            text-decoration: none;
          }

          &:hover {
            span {
              text-decoration: underline;
            }
          }

          .highlight {
            color: #ed1c22;
          }

          sup {
            color: #AAAAAA;
          }
        }

        &.letter {
          a::first-letter {
            color: #ED1C22;
          }

          &.selected {
            a::first-letter {
              color: #FFFFFF;
            }
          }
        }
      }

      &.special {
        li {
          a {
            font-size: 1rem;
            line-height: 1.4rem;
          }

          &.letter {
            a::first-letter {
              color: inherit;
            }
          }
        }
      }
    }

    .filtering {
      .menu-city-list {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;

        &.available.collapse {
          display: block !important;
        }

        li {
          display: none;
          opacity: 0;
          height: 0px;
          overflow: hidden;
          transition: opacity 0.2s;

          &.match {
            display: list-item;
          }

          &.visible {
            opacity: 1;
            height: 3rem;
          }

          &.letter {
            a::first-letter {
              color: inherit;
            }
          }

          a {
            font-size: 1.1rem;
          }
        }
      }

      .list-cities-show-all {
        display: none !important;
      }
    }

    .filter-results {
      background: #fff;
      position: absolute;
      z-index: 1;
      left: 15px;
      right: 15px;
      padding: 0;
      max-height: 149px;
      overflow-y: auto;

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        line-height: 29px;
        padding: 0;
        border: 1px solid #ececec;
        border-top: none;
        text-align: left;

        &:after {
          content: attr(data-count);
          color: #cdcdcd;
          float: right;
          margin-top: -2.4em;
          margin-right: 1em;
          cursor: pointer;
        }

        &:hover a {
          background: #ffeba0;
        }

        a {
          padding: 0 14px;
          display: block;
          color: #464646;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }

          sup {
            display: none;
          }

          .highlight {
            color: #ed1c22;
          }
        }

        &.disabled {
          a {
            color: #999;
          }
        }
      }
    }
  }
}

.location-select {
  position: relative;
}

.menu-mylocation {
  background: #fff;
  line-height: 2.18rem;
  border-radius: 0.25rem;
  height: 4.75rem;

  color: #464646;

  .left, .right {
    display: inline-block;
  }

  h4 {
    font-weight: normal;
    font-size: 0.75rem;
    color: #999;
    line-height: 1.125rem;
  }

  a {
    font-size: 0.875rem;
    color: #464646;
    line-height: 1.355rem;
    display: inline-block;
  }

  .my-location-edit {
    font-size: 0.75rem;
    line-height: inherit;
    color: #1277c7;
  }

  .left {
    a {
      display: block;
    }
  }

  .current-location {
    background: #fff url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAzNC41Ij48Zz48ZyBpZD0icG9pbnQiPjxwYXRoIHN0eWxlPSJmaWxsOiNlZTFjMjQ7IiBkPSJNMTIuNSwzMkMxNCwzMiwyNSwxOS40LDI1LDEyLjVhMTIuNSwxMi41LDAsMCwwLTI1LDBDMCwxOS40LDExLDMyLDEyLjUsMzJaTTQsMTIuNUE4LjUsOC41LDAsMSwxLDEyLjUsMjEsOC41MSw4LjUxLDAsMCwxLDQsMTIuNVoiLz48cGF0aCBzdHlsZT0iZmlsbDojZWUxYzI0OyIgZD0iTTE4LjE4LDI5LjM0YS40OS40OSwwLDAsMC0uNjQuMy41LjUsMCwwLDAsLjMuNjRjMS4yMy40MywxLjY2LjkzLDEuNjYsMS4yMiwwLC42NS0xLjg4LDEuNjgtNS40MiwxLjk0YTIwLjgzLDIwLjgzLDAsMCwxLTMuMTYsMEM3LjM4LDMzLjE4LDUuNSwzMi4xNSw1LjUsMzEuNWMwLS4yOS40My0uNzksMS42Ni0xLjIyYS41LjUsMCwwLDAsLjMtLjY0LjQ5LjQ5LDAsMCwwLS42NC0uM0M0LjksMzAsNC41LDMwLjg5LDQuNSwzMS41YzAsMS44MiwzLjQyLDIuNzIsNi4zNCwyLjk0LjUzLDAsMS4wOS4wNiwxLjY2LjA2czEuMTMsMCwxLjY2LS4wNmMyLjkyLS4yMiw2LjM0LTEuMTIsNi4zNC0yLjk0QzIwLjUsMzAuODksMjAuMSwzMCwxOC4xOCwyOS4zNFoiLz48L2c+PC9nPjwvc3ZnPg==') no-repeat 15px 38px;
    background-size: 1.6875rem 2.25rem;

    position: relative;
    z-index: 2;
    left: -1.25rem;
    height: 5.6875rem;
    top: -0.5rem;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);

    a, div {
      line-height: 1.4rem;
    }

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      right: -1.3125rem;
      height: 6.1875rem;
      width: 1.75rem;
      top: -0.1875rem;
      z-index: 2;
      background: url('../../../images/frontend/locations-border.png');
    }

    .my-location-name {
      font-size: 0.875rem;
      margin-right: -1.125rem;
    }
  }

  div.suggest {
    line-height: 3.75rem;
    display: none;
    transition: width 0.2s;
    top: 0;
    height: 4.75rem;
    white-space: nowrap;
    position: absolute;
    z-index: 1;
    background: #333;
    width: 0;
    overflow: hidden;
    background: #333333;
    margin-left: -1.4375rem;
    border-radius: 4px;

    .container {
      width: auto;
    }

    .close {
      background: no-repeat url('../../../images/frontend/close-circle.svg');
      background-size: 18px 18px;
      background-position: center center;
      display: block;
      width: auto;
    }

    input[type="text"] {
      opacity: 0;
      transition: opacity .5s;

      border: 1px solid transparent;
      border-radius: 3px;
      line-height: 1.875rem;
      font-size: 0.75rem;
      min-width: 10.5rem;
      display: block;
      width: 100%;

      background-color: #fff;
      background-repeat: no-repeat;
      background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDE4Qzg4ODA0MEIwMTFFOUE4NzE4MzNFNUExRDM5Q0IiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDE4Qzg4N0Y0MEIwMTFFOUE4NzE4MzNFNUExRDM5Q0IiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RkUxOTVBOTUzRTVCMTFFOUFBRjhEQzRFQzFDMEExNUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RkUxOTVBOTYzRTVCMTFFOUFBRjhEQzRFQzFDMEExNUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6rmCHDAAAB1klEQVR42qTUT0hUURTH8Tc2Uom1SIxEcOMiUUJqoSgEFo4iEUj+gRbiKkQpXAhSIszMInUTDARNja5EcGFM4EJR1FoItmjhShRtkTtBJSUs0crvhd8M1+czfXTgwz0z8+a882buuYFIJOK44iJa8Qi3cR0/sYZJDGE1dXE4HD725QxXsTqs4B1qVczEZdxCN5YQwyXHI4JW/gRvdZNfGFFH67iKMnVehE6U4gH2vDq8ZxVbwE3dIIkvmMOAuuzBH1Tp8U88svFaq/lytbryikP045leP45Go1Xugg9Rgt9ocT/CKfEGM8qfuwvWK5/AsnP+eKX1Pl1esQsWK591/MVHrZn6zdMFU1tjw2fBfewoz7ULbim/5rPgBWQr/24XXFN+12fBShX9q2FIFxxXbv6cfB8Fn2pdYPy27YJJ7TszSoO661nRgGblMfe2MWPWZc3yqPXbeIXZq2PKP+G91+iZN18qb9Jp8kLzmoMCNGIKwwjo2juacc/Tphdt+IEb6MMiNvFNXdXo2s9azaExzcYuP+34SqBQ87rq+mwXHxBCBTq8igY8Dlg7spCnA3ZD825Hu+Y6dcNQ8Ix/0xwUX//xedw6LEyniQznP4M9GFenB5g/EmAAEGplb8df+CcAAAAASUVORK5CYII=");
      background-position: right 15px center;
      background-size: 15px 15px;

      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &::-webkit-input-placeholder {
        color: #999999;
      }

      &:-moz-placeholder {
        color: #999999;
      }

      &::-moz-placeholder {
        color: #999999;
      }

      &:-ms-input-placeholder {
        color: #999999;
      }

      &:active, &:focus {
        outline: 0;
      }
    }

    &.in {
      input {
        opacity: 1;
      }
    }
  }

  .nearest-container {
    vertical-align: top;
    display: inline-block;
    height: 4.25rem;
    min-width: 12rem;
    transition: width 1s;
    overflow: hidden;
    white-space: nowrap;

    .nearest-locations {
      line-height: 0;

      a {
        text-decoration: none;

        sup {
          color: #a1a1a1;
          font-size: 0.61rem;
        }

        span {
          transition: transform 0.3s;
          transform: scale(0.01);
          display: inline-block;
          text-decoration: underline;

          color: #fff;

          &.in {
            transform: scale(1);
            color: inherit;
          }
        }

        &:focus, &:hover {
          span {
            text-decoration: none;
          }
        }
      }

      a.selected {
        color: #da0514;
      }
    }

    .spinner {
      content: url('../../../images/frontend/spinner.svg');
      height: 2.5rem;
      width: 2.5rem;
      margin-top: -0.5rem;
      margin-left: -0.375rem;
    }
  }

  & > ymaps {
    margin-top: 2px;
    margin-left: -1px;

    .ymaps-2-1-74-search__suggest-item {
      color: #999;
    }

    .ymaps-2-1-74-search__suggest-highlight {
      color: #ed1b24;
      font-weight: normal;
    }
  }
}


@media screen and (max-width: 767px) {
  #menu-city-list {
    position: absolute;
    right: -80vw;
    overflow: hidden;
    width: 100vw;
    top: 0;
    z-index: 8;

    &:before {
      content: " ";
      left: -0.625rem;
      width: 0.625rem;
      height: 100%;
      display: block;
      position: absolute;
      z-index: 20;
      box-shadow: 2px 0px 10px rgba(0,0,0,0.4);
    }
  }

  body {
    transition: left 0.2s;
    left: 0;
    position: relative;
    &.locations-collapse {
      left: -80vw;
    }
  }

  .menu-city-wrapper {
    .menu-city-inner {
      color: #fff;
      background-color: transparent;

      min-width: auto;
      max-width: initial;

      h2 {
        line-height: 1.5rem;
        font-size: 0.9rem;

        &.available, &.not-in-stock {
          display: block;
          color: #808080;
        }
      }

      .subtitle {
        font-size: 0.75rem;
      }

      .menu-city-list {
        li {
          a {
            &:link, &:visited {
              color: #fff;
            }
          }
        }
      }

      .filtering .menu-city-list.special {
        display: none;
      }

      .list-cities-header {
        border-bottom: 0;
      }
    }
  }

  .menu-city-arrow {
    display: none;
  }

  .close {
    color: #fff;
  }

  .menu-city-list {
    &.available {
      -moz-column-count: unset;
      -webkit-column-count: unset;
      column-count: unset !important;
    }

    li {
      line-height: 1.625rem;
      border-bottom: 1px solid #626262;
      font-size: 1rem;
      position: relative;

      &.selected {
        a {
          position: relative;
          background: none;
          z-index: 2;
        }
      }
    }
  }

  .menu-mylocation {
    background: none;
    position: static;
    height: auto;

    .left, .nearest-container {
      display: block;
      background: #fff;
      border: none;
    }

    .selected-container {
      display: none;
    }

    .nearest-container {
      min-height: 14.4rem;
      display: none;
      height: auto;

      &.in {
        display: block;
      }

      .nearest-locations {
        visibility: collapse;
        
        h4, a, span {
          visibility: visible;
        }

        a {
          display: block;
          text-decoration: none;
          border-bottom: 1px solid #ddd;

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      .spinner {
        visibility: visible;
      }

    }

    div.suggest {
      position: static;
      width: auto;
      height: 0rem;
      transition: height 0.2s;
      border-right: 0;
      border-radius: 0;
      background: none;
      margin-left: auto;

      &.in {
        height: 3.5rem;
        display: block;
        width: auto !important;
      }

      input[type="text"] {
        border-radius: 3px;
        margin-top: 0px;
        border-radius: 2px;
        width: 100%;
        font-size: 1rem;
        background-size: 17px 17px;
        border: 1px solid #ee1c24;
        border: 1px solid #ee1c24;

        box-shadow: none !important;

        &:active, &:focus {
          outline: none;
        }
      }
    }

    .current-location {
      box-shadow: none;
      height: auto;
      border-radius: 0;
      left: auto;
      background-position: 1rem 1rem;

      &:after {
        display: none;
      }
    }

  }

  .close {
    text-shadow: none;

    &:hover {
      color: #fff;
    }
  }

  ymaps[data-suggest="true"] > ymaps > ymaps * {
    font-size: 1.125rem;
    line-height: 2.25rem;
  }

  ymaps[data-suggest="true"] > ymaps > ymaps > ymaps > ymaps {
    color: #ed1c22;
    font-weight: normal;
  }
}

@media screen and (min-width: 768px) {
  .menu-city-list.collapse {
    display: block !important;
  }
}

@media screen and (max-width: 991px) {
  .menu-city-wrapper {
    .menu-city-inner {
      .menu-city-list {
        &.available {
          clear: both;
          -moz-column-count: 2;
          -moz-column-gap: 2rem;
          -webkit-column-count: 2;
          -webkit-column-gap: 2rem;
          column-count: 2;
          column-gap: 2rem;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .menu-city-wrapper {
    background-color: #FFFFFF;
    z-index: 110 !important;

    .menu-city-inner {
      color: #464646;

      .close {
        color: #464646;
      }

      h2 {
        font-size: 1.25rem;

        .in-city {
          padding: 0.3rem 0.5rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          max-width: 8.125rem;
          vertical-align: middle;
        }
      }

      .subtitle {
        font-size: 0.875rem;
      }

      .menu-city-search {
        input {
          height: 2.5rem;
          font-size: 0.875rem;
        }
      }

      .menu-city-list {
        li {
          border-bottom: unset;
          border-top: 1px solid #CCCCCC;

          &:first-child,
          &.selected,
          &.selected::after {
            border: unset;
          }

          a {
            font-size: 1.125rem;

            &:link,
            &:visited {
              color: #464646;
            }

            sup {
              font-size: 0.75rem;
              color: #CCCCCC;
            }
          }
        }
      }

      .list-cities-show-all {
        a {
          &.collapsed {
            &:link,
            &:visited {
              color: #464646;
            }
          }

          font-size: 1rem;

          .fa-angle-down {
            display: none;
          }

          .fa-angle-up {
            display: inline-block;
          }

          &.collapsed {
            .fa-angle-down {
              display: inline-block;
            }

            .fa-angle-up {
              display: none;
            }
          }

          .fas {
            color: #EE1C24;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
