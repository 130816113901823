@media screen and (min-width: 992px) and (max-width: 1199px) {
  .menu-city-wrapper {
    .menu-city-inner {
      .menu-city-list {
        &.available {
          -moz-column-count: 4;
          -webkit-column-count: 4;
          column-count: 4;
        }
      }
    }
  }
}