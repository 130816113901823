article {
  &.content-opened {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #FFFFFF;

    h2 {
      margin: 16px 0;
    }

    .announce {
      font-size: 0.75rem !important;
      line-height: 1.125rem !important;
    }

    .preview {
      display: none;
    }

    .description {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;

      blockquote, q {
        border-top: 1px solid #CCCCCC;
        border-bottom: 1px solid #CCCCCC;
        padding: 25px 0;
        font-size: 1.125rem;
        line-height: 1.4;
        margin: 36px 0 36px 0;
      }

      img {
        width: 100%;
        height: auto;
      }

      .announce {
        display: none;
      }
    }

    .btn-close {
      width: 21px;
      height: 20px;
      background-size: contain;
    }

    .content-opened-body {
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .description {
    .announce {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 992px) {
  article {
    &.content-opened {
      .description {
        blockquote, q {
          width: 80%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  article {
    &.content-opened {

      h2 {
        margin: 30px 0;
      }

      .description {
        blockquote, q {
          width: 100%;
        }
      }
    }
  }
}