#loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-image: url("../../../images/frontend/loading_bg_sprite.png");
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 10010;
  div {
    width: 44px;
    height: 44px;
    background: url("../../../images/frontend/better_loading.gif") center center no-repeat;
  }
}

.loading {
  position: relative;
}

.loading:after {
  content: '';
  position: absolute;
  width: 44px;
  height: 44px;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background: url("../../../images/frontend/better_loading.gif") no-repeat center center, url("../../../images/frontend/loading_bg_sprite.png") no-repeat 0 -108px;
  opacity: 0.8;
  z-index: 100;
}
