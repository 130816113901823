.pagination {
  margin: 0;

  .page-item {
    .page-link {
      text-decoration: none;

      &:hover {
        color: #EE1C24;
      }

      &:focus {
        box-shadow: unset;
      }
    }

    &.active {
      .page-link {
        background-color: #EE1C24;
        border-color: #EE1C24;

        &:hover {
          color: #FFFFFF;
        }
      }
    }
  }
}