.search-main {
  background: #F5F5F5;

  h5 {
    font-size: 1.1rem;
    &:before {
      margin-right: 0.5rem;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: "\F002";
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      -webkit-font-feature-settings: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      position: absolute;
    }

    a {
      color: #FFFFFF;
      text-decoration: none;
      &:hover {
        color: #FFFFFF;
      }
    }
  }

  .line-white {
    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;

    .toggleize .no-more {
      background: #FFFFFF;
    }
  }

  .line-white-arrow {
    content: '';
    position: absolute;
    display: none;
    width: 13px;
    height: 7px;
    left: 0;
    margin-left: -8px;
    top: -7px;
    background: url(../../../images/frontend/toggleize-dropdown-arrow.png) no-repeat -3px -10px;
  }

  .line-gray {
    border-bottom: 1px solid #CCCCCC;
    &:last-child {
      border-bottom: none;
    }
  }

  div.body-type {
    padding-left: 1.875rem !important;
    background: url(../../../images/frontend/icons/search-sorting-icon-1.png) no-repeat left top;
  }

  div.price-range {
    padding-left: 1.875rem !important;
  }

  div.gear-type {
    padding-left: 1.875rem !important;
    background: url(../../../images/frontend/icons/search-sorting-icon-3.png) no-repeat left top;
    flex: 0 0 20%;
  }

  div.enginedisplacement-range {
    padding-left: 1.875rem !important;
    background: url(../../../images/frontend/icons/search-sorting-icon-2.png) no-repeat left top;
  }
  div.drive-type {
    padding-left: 1.875rem !important;
    background: url(../../../images/frontend/icons/search-sorting-icon-drive.png) no-repeat left top;
    flex: 0 0 10%;
  }
  div.engine-fuel {
    padding-left: 1.875rem !important;
    background: url(../../../images/frontend/icons/search-sorting-icon-fuel-1.png) no-repeat left top;
  }

  .toggleize-item[data-value=гибрид] {
    margin-left: 75px;
    &:before {
      content: '';
      position: absolute;
      display: block;
      left: -25px;
      background-image: url(../../../images/frontend/icons/search-sorting-icon-fuel-2.png);
      height: 20px;
      width: 15px;
      cursor: default;
    }
  }

  .toggleize-checkbox.calc {
    border-radius: 3px;
    padding: 0 5px;

    span {
      border-bottom: 1px dotted #EE1C24;
    }

    &.checked {
      background-color: #999999;
      color: #FFFFFF;

      span {
        border-bottom: 0;
      }
    }
  }

  .toggleize-checkbox.calc {
    border-radius: 3px;
    padding: 0 5px;

    color: #FF6500;

    span {
      border-bottom: 1px dotted #FF6500;
    }

    &.checked {
      background-color: #FF6500;
      color: #FFFFFF;

      span {
        border-bottom: 0;
      }
    }
  }

  .toggleize-checkbox.tradein {
    border-radius: 3px;
    padding: 0 5px;

    color: #1178C6;

    span {
      border-bottom: 1px dotted #1178C6;
    }

    &.checked {
      background-color: #1178C6;
      color: #FFFFFF;

      span {
        border-bottom: 0;
      }
    }
  }

  .toggleize-checkbox.label-made_in_rk, .toggleize-checkbox.label-made_in_rf {
    border-radius: 3px;
    padding: 0 5px;

    color: #00AFC2;

    span {
      border-bottom: 1px dotted #00AFC2;
    }

    &.checked {
      background-color: #00AFC2;
      color: #FFFFFF;

      span {
        border-bottom: 0;
      }
    }
  }

  .toggleize-checkbox.label-made_in_rf {
    color: #1178C6;

    span {
      border-color: #1178C6;
    }

    &.checked {
      background-color: #1178C6;
    }
  }

  .toggleize-checkbox.import_type {
    border-radius: 3px;
    padding: 0 5px;

    color: #00AFC2;

    span {
      border-bottom: 1px dotted #00AFC2;
    }

    &.checked {
      background-color: #00AFC2;
      color: #FFFFFF;

      span {
        border-bottom: 0;
      }
    }
  }

  .toggleize-checkbox {
    &.calc, &.tradein, &.label-made_in_rk, &.label-made_in_rf, &.import_type {
      cursor: pointer;

      &.disabled {
        cursor: default;
      }
    }
  }

  &.search-salons {
    .search-seller .toggleize .toggleize-head li:after {
      display: none;
    }
    .line-gray:last-child {
      border-bottom: 1px solid #CCCCCC;
    }
  }
}

@media (max-width: 575px) {
  .credit-tradein-container {
    order: -1;
  }

  .search-main {
    background: #1178C5;
    color: #FFFFFF;
    border-radius: 4px;

    .line-gray {
      border-bottom: 0;
    }
    &.search-salons {
      .line-gray:last-child {
        border-bottom: none;
      }
    }

    .line-white {
      background: none;
      border: none;
    }

    .toggleize .toggleize-head li a {
      color: #FFFFFF;
    }

    .toggleize .toggleize-head li.no-more {
      display: none;
    }

    .credit-tradein-container {
      width: 140% !important;
    }

    .btn-light {
      color: #464646;
    }

    .form-line {
      width: 100%;
      display: block;
      flex: none !important;
      position: relative;

      &.body-type, &.price-range, &.gear-type, &.enginedisplacement-range, &.drive-type, &.engine-fuel {
        padding-left: 0 !important;
        background: none !important;
      }

      &:after {
        content: " ";
        display: block;
        height: 1px;
        width: 100%;
        background: #4293d2;
        position: absolute;
        bottom: 0;
      }

      &.price-sorting {
        &:after {
          display: none;
        }
      }

      label {
        margin-bottom: 0;
        line-height: 2.3rem;
      }
    }

    .line-gray {
      width: auto;
    }

    .toggleize {
      .toggleize-head {
        height: 0;

        ul {
          visibility: hidden;
          height: 0;
        }

        a.more {
          display: none;
        }
      }

      .toggleize-all {
        border-top: 0;
        background: #FFFFFF;
        border-radius: 4px;

        &:after {
          display: none;
        }

        h4 {
          color: #1178C5;
          font-size: 1rem;
        }

        .toggleize-item {
          line-height: 2.3rem;
          border-bottom: 1px solid #CCCCCC;

          &:before {
            display: none;
          }

          a {
            border-bottom: 0;
          }

          &:after {
            content: attr(data-count-text);
            font-size: inherit;
            position: static;
            float: right;
            color: #CCCCCC;
          }

          &[data-value=гибрид] {
            margin-left: 0;
          }

          &[data-count='-1']:after, &[data-count='0']:after {
            display: none;
          }
        }

        .col:last-child {
          .toggleize-item:last-child {
            border-bottom: 0;
          }
        }

        &.with-radios {
          .toggleize-item {
            line-height: 1.8rem;
            border-bottom: 0;
            &:after {
              display: none;
            }
          }
        }

        .mobile-free-range-select {
          margin-bottom: 0.5rem;

          .title {
            color: #1178C5;
            font-size: 0.875rem;
            line-height: 1.25rem;
            margin-bottom: 0.5rem;
          }

          input {
            border: 1px solid #CED4DA;
            width: 5.375rem;
            height: 1.875rem;
            padding: 0.25rem;

            &#price_range_from {
              border-right: unset;
            }

            &::placeholder {
              color: #D3D3D3;
            }
          }

          label {
            display: none;
          }

          .btn-secondary {
            background: #F5F5F5;
            margin-left: 0.5rem;
            height: 1.875rem;
            border-color: #CED4DA;
            color: #464646;
            margin-top: -0.125rem;
            border-bottom: 1px solid #CED4DA;
            line-height: 1.375rem;
          }
        }
      }
    }

    .toggleize-checkbox {
      background: none !important;
      color: #FFFFFF !important;

      &.checked {
        color: #FFFFFF !important;
      }

      input[type="checkbox"] {
        display: inline-block !important;
        position: relative;
        top: 0.25rem;
        height: 1rem;
        width: 1rem;
      }

      span {
        border-bottom: 0 !important;
      }

      &.disabled {
        color: #A6C3E3 !important;

        span {
          border-bottom: 0 !important;
        }
      }
    }

    #ajax-search-btn a {
      &.loading {
        &:before {
          content: url('../../../images/frontend/spinner.svg');
          display: inline-block;
          height: 3rem;
          width: 3rem;
          position: absolute;
          top: -5px;
          left: 50%;
          margin-left: -1.5rem;
        }
      }
    }
  }

  .ui-overlap {
    z-index: 1030;
  }
}

@media (min-width: 575px) {
  #search-form-collapse {
    display: block;
  }
}

@media (min-width: 360px) and (max-width: 575px) {
  .mobile-free-range-select {
    .btn-secondary {
      width: 5.375rem;
    }
  }
}

@media (max-width: 359px) {
  .mobile-free-range-select {
    .btn-secondary {
      width: 2.813rem;
    }
  }
}

.content .search-main a:link, .content .search-main a:visited {
  text-decoration: none;
}

@media (max-width: 575px) {
  .import_type_wrapper {
    order: 4
  }
}
