.social-buttons {
  li {
    padding: 0 !important;

    a {
      display: block;
    }
  }
}

.ya-share2-container {
  .ya-share2-title {
    margin-right: 5px;
  }

  div {
    display: inline-block;
    vertical-align: middle;
  }
}

.footer-social-buttons {
  img {
    width: 15px;
    margin-right: 3px;
  }

  a {
    &:link,
    &:visited {
      text-decoration: none;
      border-bottom: 0 !important;

      span {
        border-bottom: 1px solid #808080;
      }
    }

    &:hover {
      color: #EE1C24;
      border-bottom: 0;

      span {
        border-bottom: 1px solid #EC6A72;
      }
    }
  }
}

@media (min-width: 992px) {
  .social-buttons {
    li {
      margin-left: 5px;
    }
  }
}

@media (max-width: 991px) {
  .social-buttons {
    display: inline-block;
    width: 100%;
    border-top: 1px solid #999999;
    padding-top: 10px;

    li {
      display: inherit;
      margin-right: 5px;

      a {
        width: 24px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}