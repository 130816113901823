.page-feedback {
  h1 {
    &.title-salon {
      font-size: 1.875rem;
      margin-bottom: 0.5rem !important;
    }
  }

  form {
    label {
      font-weight: bold;
    }

    .btn-light {
      border-color: #CCCCCC;
    }
  }
}