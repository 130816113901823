.vendor-img-lg {
  &[data-value] {
    a {
      &:before {
        content: '';
        color: #464646;
        display: block;
      }
      display: block;
      position: relative;
      color: transparent;
      border: none;
      font-size: 0;
      line-height: 0;
    }

    &.disabled a:before {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }

  @import 'sprites/48x48.generated';
}

.vendor-img-md {
  &[data-value] {
    a {
      &:before {
        content: '';
        color: #464646;
        display: block;
        margin-top: 0.5rem;
      }
      display: block;
      position: relative;
      color: transparent;
      border: none;
      font-size: 0;
      line-height: 0;
    }

    &.disabled a:before {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }

  @import 'sprites/32x32.generated';
}

.vendor-img-sm {
  &[data-value] {
    a {
      &:before {
        content: '';
        color: #464646;
        display: block;
        margin-top: 0.5rem;
      }
      display: block;
      position: relative;
      color: transparent;
      border: none;
      font-size: 0;
      line-height: 0;
    }

    &.disabled a:before {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }

  @import 'sprites/24x24.generated';
}