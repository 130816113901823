article {
  &.video-review {
    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1.35;
    }
  }
}

@media (max-width: 991px) {
  article {
    &.video-review {
      iframe {
        width: 100%;
        height: auto;
      }
    }
  }
}