.with-label-made_in_rf {
  a::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 20px;
    z-index: 21;
    background: url(../../../../images/projects/autovsalone/made_in_rf.png);
    background-size: contain;
  }
}

.text-flag.label-made_in_rf {
  background-color: #1178C6;
}

@media (max-width: 767px) {
  .made_in {
    background: url(../../../../images/projects/autovsalone/made_in_rf.png);
    background-size: cover;
  }
}
