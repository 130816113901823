.a-places-wrap {
  .a-places-inner {
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;

    a {
      &.a-place-rblock,
      &.a-place-lblock {
        background: #EE1C24;
        color: #FFFFFF;
        position: absolute;
        text-align: center;
        text-decoration: none;
        z-index: 20;
      }

      .a-place-rblock-title,
      .a-place-lblock-title {
        margin: 10px 0 0 0;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1;
      }

      &.a-place-rblock {
        &.orange {
          background: #FF5F18;
        }

        &.blue {
          background: #3766C1;
        }
      }

      &.a-place-lblock {
        &.orange, &.blue {
          background-blend-mode: soft-light;
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;
        }

        &.orange {
          background-color: rgba(255, 95, 24, 0.8);
        }

        &.blue {
          background-color: rgba(55,102,193,0.8);
        }
      }
    }

    .a-place-left {
      width: 525px;

      .a-place-lblock-title {
        margin-top: 90px;
      }
    }

    .a-place-right {
      width: 172px;

      .a-place-rblock-title {
        margin-top: 90px;
      }
    }

    .a-place-mid {
      right: 75px;
    }

    .a-place-lvl {
      position: absolute;
      border: 2px solid #464646;
      border-right: 0;
      z-index: 10;

      .a-place-lvl-title {
        color: #FFFFFF;
        font-size: 1.125rem;
        font-weight: bold;
        background: #464646;
        display: inline-block;
        padding: 11px 20px;
        line-height: 20px;
        word-wrap: break-word;
        width: 230px;
        text-align: center;
      }

      .a-place-lvl-desc {
        background: #FFFFFF;
        width: 378px;
        padding: 18px 20px;

        p {
          line-height: 0.875rem;
          margin-bottom: 8px;

          &:last-child {
            margin: unset;
          }
        }
      }
    }

    .a-place-lvl__lines {
      position: absolute;
      border: 2px solid #464646;
      border-bottom: 0;
      border-top: 0;
      z-index: 10;
      margin-left: 290px;
    }


    .a-place-pop-up {
      position: absolute;
      background: #FFFFFF;
      box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.55);
      z-index: 30;
      padding-bottom: 36px;

      .a-place-pop-up-cont-left {
        width: 285px;
      }

      .a-place-pop-up-top-img {
        margin-top: 20px;

        .ds-arrow {
          width: 100%;
          height: 1px;
          background: #CCCCCC;
          position: relative;
          margin: 8px auto;

          &:before {
            left: -1px;
          }

          &:after {
            right: -1px;
          }

          &:before, &:after {
            content: '';
            position: absolute;
            display: block;
            width: 1px;
            height: 1px;
            z-index: 1;
            border: 1px solid #CCCCCC;
            border-radius: 1px;
            background: #CCCCCC;
            top: -1px;
          }
        }

        img {
          width: 100%;
          height: auto;
        }

        .vertical {
          font-size: 9px;
          font-weight: normal;
          color: #999;
          line-height: 12px;
          transform: rotate(-90deg);
          width: 221px;
          position: absolute;
          right: -86px;
          top: 250px;
          padding-top: 3px;

          &:before {
            left: -1px;
          }

          &:after {
            right: -1px;
          }

          &:before, &:after {
            content: '';
            position: absolute;
            display: block;
            width: 1px;
            height: 1px;
            z-index: 1;
            border: 1px solid #CCCCCC;
            border-radius: 1px;
            background: #CCCCCC;
            top: -1px;
          }

          .line {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            height: 1px;
            background: #CCCCCC;
          }
        }
      }

      .a-place-pop-up-top-desc {
        margin-top: 20px;
      }

      .a-place-pop-up-mid-img {
        margin-top: 14px;
      }

      .a-place-pop-up-cont,
      .a-place-pop-up-cont-promo {
        .a-place-pop-up-title {
          font-size: 1.5rem;
          font-weight: bold;
          color: #EE1C24;
        }

        .a-place-pop-up-price {
          font-size: 1.125rem;
          font-weight: normal;
          line-height: 1.625rem;
        }

        .a-place-pop-up-text-info {
          margin-top: 36px;
        }

        .a-place-clr {
          margin-top: 36px;

          p {
            margin: unset;
          }

          a {
            color: #464646;
            text-decoration: underline;

            &:hover {
              color: #EE1C24;
              text-decoration: none;
            }
          }
        }

        .a-place-pop-up-top-button,
        .a-place-pop-up-right-button {
          margin-top: 36px;

          .btn-red {
            font-size: 1rem;
            text-decoration: none;
            color: #FFFFFF;
            padding: 10px 20px;
          }
        }

        .a-place-pop-up-top-li, .a-place-pop-up-right-li {
          margin-top: 22px;

          p {
            margin: 0;

            a {
              color: #464646;
              text-decoration: underline;

              &:hover {
                color: #EE1C24;
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .a-place-pop-up-top-img,
    .a-place-pop-up-mid-img,
    .a-place-pop-up-img-right {
      p {
        margin: 5px 0;
      }
    }

    #a-place-pop-up-top {
      top: 0;
      width: 910px;
      left: 50%;
      margin-left: -455px;
      margin-top: -16px;
    }

    #a-place-pop-up-mid-b1, #a-place-pop-up-mid-b2 {
      padding-top: 36px;
    }

    #a-place-pop-up-mid-b1 {
      top: 598px;
    }

    #a-place-pop-up-mid-b1, #a-place-pop-up-mid-b2 {
      width: 790px;
      left: 50%;
      margin-left: -395px;
      padding-top: 36px;
    }

    #a-place-pop-up-mid-b2 {
      top: 1135px;
    }

    #a-place-pop-up-right-b1, #a-place-pop-up-right-b2 {
      padding: 36px 40px;
      right: 20px;
    }

    #a-place-pop-up-right-b1 {
      top: 187px;
    }

    #a-place-pop-up-right-b2 {
      top: 733px;
    }

    #a-place-pop-up-left-b1, #a-place-pop-up-left-b2 {
      padding: 36px 40px;
      left: 125px;
      width: 605px;
    }

    #a-place-pop-up-left-b1 {
      top: 350px;
    }

    #a-place-pop-up-left-b2 {
      top: 890px;
    }
  }
}

@media (min-width: 992px) {
  .a-places-wrap {
    padding: 60px 0;

    .a-places-inner {
      min-height: 1498px;
    }
  }
}

@media (max-width: 991px) {
  .a-places-wrap {
    padding: 20px 0;

    .a-places-inner {
      background: unset;

      .a-place-rblock,
      .a-place-lblock,
      .a-place-lvl__lines,
      .a-place-lvl {
        display: none;
      }

      .a-place-pop-up {
        position: relative;

        .a-place-pop-up-top-img,
        .a-place-clr {
          margin-top: unset;
        }

        .a-place-pop-up-cont {
          .a-place-pop-up-text-info {
            margin-top: 10px !important;
          }

          .a-place-clr {
            margin-top: unset;
          }

          .a-place-pop-up-img-right {
            text-align: left !important;

            img {
               margin-top: 15px;
             }
          }

          .a-place-pop-up-right-button {
            position: relative;
            margin-top: 20px;
          }
        }

        .a-place-pop-up-cont-left {
          width: auto;
        }

        .a-place-pop-up-top-img {
          .vertical {
            right: -95px !important;
            top: 195px !important;
          }
        }
      }

      #a-place-pop-up-top,
      #a-place-pop-up-mid-b1,
      #a-place-pop-up-right-b1,
      #a-place-pop-up-left-b1 {
        display: block !important;
        margin: unset;
        width: auto;
        left: auto;
        right: auto;
        top: auto;
        box-shadow: none;
        background: none;
        padding-top: unset;
        z-index: unset;
        padding-bottom: 20px;
      }

      #a-place-pop-up-top,
      #a-place-pop-up-mid-b1,
      #a-place-pop-up-right-b1 {
        border-bottom: 1px solid #CCCCCC;
        margin-bottom: 20px;
      }

      #a-place-pop-up-mid-b1,
      #a-place-pop-up-right-b1 {
        padding-left: 15px;
        padding-right: 15px;
      }

      #a-place-pop-up-left-b1 {
        padding-left: 15px;
        padding-right: 30px;
      }
    }
  }
}