.a-places-inner {
  background-image: url('../../../../../images/projects/autovsalone/places-inner.jpg');

  .a-place-rblock-top {
    top: 34px;
    right: 75px;
    width: 703px;
    height: 55px;
  }

  a {
    &.a-place-lblock {
      &.orange,
      &.blue {
        background-image: url('../../../../../images/projects/autovsalone/place-pop-up-promo.jpg');
      }
    }
  }

  .a-place-left {
    left: 78px;
    height: 221px;
  }

  .a-place-left-b1 {
    top: 255px;
  }

  .a-place-left-b2 {
    top: 795px;
  }

  .a-place-right {
    right: 75px;
    height: 221px;
  }

  .a-place-right-b1 {
    top: 255px;
  }

  .a-place-mid-b1 {
    top: 729px;
  }

  .a-place-mid-b2 {
    top: 1268px;
  }

  .a-place-right-b2 {
    top: 795px;
  }

  .a-place-mid {
    width: 703px;
    height: 60px;

    .a-place-mid {
      .a-place-rblock-title {
        margin-top: 10px;
      }
    }
  }

  .a-place-lvl1 {
    top: 545px;
  }

  .a-place-lvl {
    width: 625px;
    height: 212px;
    left: 60px;

    .a-place-lvl-title {
      vertical-align: top;
      margin: -30px 0 0 95px;
    }

    .a-place-lvl-desc {
      margin: 12px 0 0 95px;
    }
  }

  .a-place-lvl1__lines {
    top: 467px;
  }

  .a-place-lvl__lines {
    width: 410px;
    height: 80px;
    left: -15px;
  }

  .a-place-lvl2__lines {
    top: 1005px;
  }

  .a-place-lvl2 {
    top: 1083px;
  }

  .a-place-pop-up-right-button {
    position: absolute;
    bottom: 0;
  }
}