.content-header {
  .preview {
    a {
      display: block;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .description {
    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1.35;

      .link-red {
        color: #464646;
        text-decoration: none;

        &:hover {
          color: #EE1C24;
        }
      }
    }

    .city,
    .views {
      color: #EE1C24;
      display: inline-block;
    }

    .views {
      margin-left: 20px;
    }

    .city {
      margin-left: 40px;

      &:before {
        content: '';
        position: absolute;
        width: 14px;
        height: 18px;
        margin-left: -20px;
        background: url(../../../../images/frontend/icons/content-city-icon.png) no-repeat top left;
        background-size: contain;
      }
    }

    .announce {
      font-size: 1rem;
      line-height: 1.375rem;
    }
  }
}

.articles-list {
  hr:last-child {
    display: none;
  }
}

@media (max-width: 991px) {
  .content-header {
    .preview {
      margin-bottom: 20px;
    }
  }
}
