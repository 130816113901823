.footer-main {
  border-top: 1px solid #464646;
  padding-top: 60px;
  margin-top: 60px;

  p {
    margin-bottom: 12px;
  }

  .footer-main-gray {
    color: #999999;

    a {
      &:link,
      &:visited {
        color: #999999;
        text-decoration: none;
        border-bottom: 1px solid #AAAAAA;
      }

      &:hover {
        color: #EE1C24;
        border-bottom: 1px solid #EC6A72;
      }
    }
  }

  .footer-main-first-stage {
    .footer-main-vendor-types {
      .main-cat-list {
        border: 1px solid #CCCCCC;
        margin-bottom: 15px;
        background-color: #F5F5F5;
        padding: 5px 16px;

        .navbar-brand {
          font-size: inherit;
          color: #464646;
          border-bottom: unset;
        }

        .vendor-type {
          margin: 5px -16px 0 -16px;;
          padding-top: 5px;
          border-top: 1px solid #CCCCCC;

          li {
            a {
              border-bottom: unset;
              padding: 5px 0;
            }
          }
        }
      }
    }

    .footer-main-page-links {
      color: #EE1C24;
    }

    .footer-main-normal {
      margin-bottom: 30px;

      a {
        &:link,
        &:visited {
          text-decoration: none;
          border-bottom: 1px solid #808080;
        }

        &:hover {
          color: #EE1C24;
          border-bottom: 1px solid #EC6A72;
        }
      }
    }
  }

  .footer-main-second-stage {
    background: #F5F5F5;
    padding: 40px 0;

    .footer-main-status-inner-continue {
      margin-top: 20px;
    }
  }
}

@media (min-width: 992px) {
  .footer-main {
    .footer-main-first-stage {
      .footer-main-vendor-types,
      .footer-main-nav-right {
        color: #EE1C24;

        a {
          color: #464646;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .footer-main {
    .footer-main-first-stage {
      .footer-main-nav-right {
        a {
          color: #464646;
        }
      }
    }
  }
}