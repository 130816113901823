body {
  font-size: 12px;
  line-height: 18px;
  font-family: Tahoma, Arial, Verdana, sans-serif;
  color: #464646;
  background: #fff;
}

/* TODO: придумать решение проблему наложения этих стилей на бутстраповые элементы, например a.btn */
.content {
  a {
    &:link,
    &:visited {
      color: #464646;
      text-decoration: underline;
    }

    &.link-blue {
      color: #266781;
      text-decoration: none;

      &:hover {
        color: #266781;
      }
    }

    &:focus {
      outline: none;
    }

    &:hover {
      color: #EE1C24;
      text-decoration: none;
    }

    &.text-gray {
      color: #999999;

      &:hover {
        color: #EE1C24;
      }
    }
  }

  ol, ul {
    list-style: none;
  }

  h1 {
    &.title {
      font-size: 2rem;
      font-weight: normal;
    }
  }
}

.link-red {
  color: #EE1C24;
  border-bottom: 1px dotted #EE1C24;
  text-decoration: none;
}

.text-gray {
  color: #999999;
}

.text-light-gray {
  color: #CCCCCC;
}

.text-blue {
  color: #266781;
}

.text-red {
  color: #EE1C24;
}

.bg-red {
  background-color: #EE1C24 !important;
}

.bg-orange {
  background-color: #FF6500 !important;
}

.bg-blue {
  background-color: #1178C6 !important;
}

.bg-light-blue {
  background-color: #00AFC2 !important;
}

.bg-green {
  background-color: #4AAF2F !important;
}

.hide {
  display: none;
}

.form-group {
  .field_with_errors {
    .form-control {
      border-color: #DC3545;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
      }
    }
  }
}

// Очень простое решение для создания сетки с расстоянием 10px между элементами.
// Не нужно городить огород с кучей :nth-child.
.gutters-10-px {
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-left: 5px;
    padding-right: 5px;
  }
}