.bnr-tall {
  .bnr-body {
    max-height: 360px !important;
    overflow: hidden;
  }
}

@media (min-width: 1200px) {
  .bnr-tall {
    .bnr-body {
      min-width: 270px !important;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bnr-tall {
    .bnr-body {
      max-width: 232px !important;
      height: 292px !important;
      overflow: hidden;
    }
  }
}

@media (min-width: 992px) {
  .bnr-tall {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .bnr-tall {
    padding-left: 15px;
  }
}

@media (max-width: 576px) {
  article.car-item + .bnr-tall {
    margin-top: 10px;
  }

  article.car-item.tile-promo + .bnr-tall {
    margin-top: 0px;
  }
}
