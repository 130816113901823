.car-item-wrapper {
  margin-bottom: 10px;

  .section-hd-car {
    .car-item-current-cont {
      h1 {
        margin-top: 25px;
      }
    }
  }
}

.section-hd-car {
  padding-bottom: 40px;

  .car-item-current-cont {
    position: relative;

    h1 {
      font-size: 1.875rem;
      margin: 0 0 15px 0;

      .subtitle {
        &.text-gray {
          font-size: 1.125rem;
          margin-left: 15px;
        }
      }

      .compare-header-link {
        border-left: 1px solid #626262;
        font-size: 1.125rem;
        color: #f5f5f5;
        line-height: 2rem;
        margin-bottom: -10px;
      }
    }

    .btn-close {
      top: 0;
      right: 0;
    }

    main {
      &.text-gray {
        margin-bottom: 18px;
      }
    }

    .tabs-content {
      padding: 10px 10px 20px 10px;
      border-bottom: 5px solid #EE1C24;

      .request-step-wrap {
        &.show-another-city {
          padding: 30px;

          .text-gray {
            font-size: 1.125rem;
          }

          .request-step-content {
            font-size: 1.125rem;
            line-height: 1rem;
            border: 2px solid #E1E1E1;
            margin-top: 25px;
            padding: 35px 25px 15px 25px;
          }

          a {
            color: #EE1C24;
          }
        }
      }

      .section {
        margin-top: 20px;

        article {
          &.content-opened {
            .content-opened-close-btn {
              &.btn-close {
                top: 10px ;
                right: 10px;
              }
            }
          }
        }
      }

      .variant-list {
        .main-text {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .link {
          padding: 10px 5px 10px 50px;
          position: relative;

          &:hover {
            background-color: #E0E0E0;
            cursor: pointer
          }

          &.current {
            background-color: #464646;

            .variant-name {
              &:before {
                background: #999999;
              }

              &:after {
                background: #999999;
                transform: rotate(270deg);
              }

              .variant-desc, a {
                color: #FFFFFF;
              }

              .variant-title {
                &:before {
                  background: #FFFFFF;
                }
              }
            }

            .variant-info {
              .variant-price {
                a {
                  color: #FFFFFF;
                }
              }
            }
          }

          .variant-name {
            &:before {
              transition: background 0.5s;
              z-index: 1;
              content: " ";
              position: absolute;
              display: block;
              background: #FFFFFF;
              width: 10px;
              height: 2px;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto 0 auto -21px;
            }

            &:after {
              transition: transform 0.5s, background 0.5s;
              content: " ";
              position: absolute;
              display: block;
              background: #FFFFFF;
              width: 2px;
              height: 10px;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto 0 auto -17px;
            }

            .variant-desc, a {
              color: #464646;
              font-size: 1rem;
              line-height: 1.25rem;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-decoration: none;
            }

            .variant-title {
              margin-top: 2px;
              font-size: 0.75rem;
              font-weight: bold;
              line-height: 0.75rem;

              &:before {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto 0 auto -28px;
                display: block;
                background: #999999;
                width: 24px;
                height: 24px;
                border-radius: 24px;
                transition: background 0.5s;
              }
            }
          }

          .variant-info {
            .variant-price {
              font-size: 1.125rem;
              line-height: 1.75rem;
              font-weight: bold;
              white-space: nowrap;
            }

            .variant-sellers {
              font-size: 0.75rem;
              font-weight: bold;
              line-height: 0.75rem;
            }
          }

          .variant-flags {
            display: flex;
            height: 40px;
            vertical-align: middle;

            .variant-price-trade-in, .variant-price-credit, .variant-price-label {
              padding: 2px;
            }

            @media (min-width: 768px) {
              .text-flag {
                padding: 3px 8px;
                display: inline-block;
                margin: auto 0 auto 10px;
              }

              .icon-flag {
                display: none;
              }
            }

            @media (max-width: 767px) {
              .text-flag {
                display: none;
              }

              .icon-flag {
                height: 20px;
                width: 20px;
                display: inline-block;
                margin: 0;
                padding: 0;
              }

              .credit--rub {
                background: url(../../../../images/frontend/icons/all_credit_rub.svg);
              }

              .credit--kzt {
                background: url(../../../../images/frontend/icons/all_credit_kzt.svg);
              }

              .trade_in {
                background: url(../../../../images/frontend/icons/trade_in.svg);
              }
            }

            div {
              padding: 3px 8px;
              display: inline-block;
              margin: auto 0 auto 10px;

              &:first-child {
                margin-left: auto;
              }
            }
          }
        }

        .odd {
          background-color: #ECECEC;
        }

        .banner-wrapper {
          padding: 10px 0;
        }

        .tabs-content-mismatched {
          &, .variant-desc, .variant-title, .variant-price a, .variant-price-credit, .variant-price-trade-in {
            border-color: #bbb;
          }
        }

        .tabs-content-description {
          h3 {
            font-size: 1.125rem;

            a {
              font-size: 0.8rem;
              margin-left: 15px;
            }
          }

          .content-cols {
            margin: 15px 0;

            .column-left {
              h3 {
                .link-red {
                  color: #EE1C24;
                  text-decoration: none;
                }
              }

              .tabs-description-panel {
                margin: 0;
              }

              .model-tab {
                padding: 15px;
                margin-left: -15px;
                box-shadow: 4px 4px 8px -1px #D9D9D9;
                border-left: 1px solid #f3f3f3;
                border-top: 1px solid #f3f3f3;

                h4 {
                  font-size: 0.75rem;
                  line-height: 1.75rem;
                  font-weight: bold;
                }

                label {
                  display: block;
                  background-color: #ECECEC;
                }

                .accordion {
                  li {
                    padding: 4px 0;

                    &:before {
                      content: "•";
                      color: #9A9A9A;
                      font-size: 0.75rem;
                      margin-right: 4px;
                      vertical-align: middle;
                    }
                  }
                }

                .car-item-footer {
                  border-bottom: 1px solid #EDEDED;
                  padding: 0 0 15px 15px;
                  margin-top: 15px;
                }

                .car-item-bnr {
                  margin-top: 15px;
                }
              }
            }
          }

          .column-right {
            display: flex;
            flex-direction: column;

            .w-buy-list {
              padding: 0;
              // компенсируем нижний отступ последнего w-buy-block
              margin-bottom: -20px;

              li {
                .w-buy-block {
                  padding: 15px;
                  margin-bottom: 20px;
                  margin-left: -15px;
                  margin-right: -15px;
                  box-shadow: 4px 4px 8px -1px #D9D9D9;
                  border-left: 1px solid #F3F3F3;
                  border-top: 1px solid #F3F3F3;
                  cursor: pointer;

                  &:hover {
                    background-color: #ECECEC !important;
                  }

                  h4 {
                    font-size: 0.875rem;
                    margin-bottom: 15px;
                    font-weight: bold;
                    color: #999999;
                  }

                  .w-buy-price,
                  .w-buy-trade-in,
                  .w-online-buy,
                  .w-price-online-buy,
                  .w-buy-credit {
                    display: inline-block;
                    font-size: 1.125rem;
                    font-weight: bold;
                    padding: 5px 10px;
                    transition: background 0.3s, color 0.1s;
                    text-decoration: none;
                  }

                  .w-price-online-buy {
                    padding-left: 0;
                  }

                  .w-price-online-buy,
                  .w-buy-price {
                    font-weight: bold;
                  }

                  .w-price-online-buy,
                  .w-buy-price {
                    color: #266781;
                  }

                  .w-buy-price,
                  .w-online-buy,
                  .w-price-online-buy,
                  .w-buy-trade-in {
                    margin: 0 6px 6px 0;
                  }

                  .w-buy-trade-in,
                  .w-online-buy,
                  .w-buy-credit {
                    color: #999999;
                    border: 1px solid #A0A0A0;
                  }

                  .w-links {
                    padding: 0;
                    transition: height 0.3s, margin 0.3s;
                    line-height: 1.5rem;
                    height: 0px;
                    overflow: hidden;

                    a {
                      text-decoration: underline;

                      &:hover {
                        text-decoration: none;
                      }
                    }
                  }

                  .w-buy-seller-link {
                    display: none;
                  }

                  .w-buy-seller-title {
                    display: block;
                    margin-top: 0;
                  }


                  &.active {
                    cursor: default;

                    h4 {
                      color: #464646;
                    }

                    .w-online-buy,
                    .w-buy-price {
                      color: #FFFFFF;
                      background-color: #EE1C24;
                    }

                    .w-buy-trade-in {
                      color: #FFFFFF;
                      background-color: #1178C6;
                    }

                    .w-buy-credit {
                      color: #FFFFFF;
                      background-color: #FF6500;
                    }

                    .w-buy-trade-in,
                    .w-online-buy,
                    .w-buy-credit {
                      border: unset;
                    }

                    .w-links {
                      height: 48px;
                      margin-top: 10px;
                    }

                    .w-buy-seller-link {
                      display: block;
                    }

                    .w-buy-seller-title {
                      display: none;
                    }
                  }
                }
              }
            }

            .subscribe-form,
            .subscribe-success {
              margin: 0 -15px;
              padding-top: 15px;
              padding-bottom: 15px;
              box-shadow: 4px 4px 8px -1px #D9D9D9;
              border-left: 1px solid #F3F3F3;
              border-top: 1px solid #F3F3F3;
            }

            .subscribe-success {
              padding-left: 15px;
              padding-right: 15px;
              display: none;
            }

            .subscribe-form {
              input.form-control {
                font-size: 0.75rem;
                padding: 3px 9px;
                height: 26px;
              }

              .btn-red {
                font-size: 0.875rem;
                margin-left: 5px;
              }

              .text-gray {
                margin-top: 10px;
              }
            }

            .subscribe-success {
              .w-buy-title {
                font-size: 1.125rem;
                margin-bottom: 10px;
              }

              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .car-index-footer {
        padding-top: 10px;
      }
    }
  }
}

.open-car {
  .title-section {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: bold;
    margin-top: 18px;
  }
}

@media (max-width: 991px) {
  .section-hd-car {
    margin-top: -1px;

    .car-item-current-cont {
      margin-left: -15px;
      margin-right: -15px;

      h1 {
        font-size: 1.5rem;

        .subtitle {
          &.text-gray {
            margin-left: 0;
            display: block;
          }
        }

        .compare-header-link {
          border-left: 0;
        }
      }

      .btn-close {
        width: 20px;
        height: 20px;
        background-size: contain;
      }

      .tabs-content {
        .variant-list {
          .odd {
            background-color: #EBEFF2;
          }

          .link {
            padding: 10px 5px 10px 30px;

            .variant-name {
              &:before {
                margin: auto 0 auto -15px;
              }

              &:after {
                margin: auto 0 auto -11px;
              }

              .variant-desc {
                font-size: 0.875rem;
                line-height: 1.25rem;
              }

              .variant-title {
                &:before {
                  margin: auto 0 auto -22px;
                }
              }
            }

            .variant-info {
              .variant-price {
                font-size: 0.875rem;
                line-height: 1.25rem;
              }
            }

            .variant-flags {
              height: 20px;

              div {
                font-size: 0.625rem;
                line-height: 0.875rem;
                padding: 2px 6px 1px 6px;
                margin-left: 5px;
              }
            }

            &.current {
              background-color: #607D8B;

              .variant-name {
                .variant-title {
                  &.text-gray {
                    color: #FFFFFF;
                  }
                }
              }
            }
          }

          .tabs-content-description {
            background-color: #464646;

            .content-cols {
              .column-left {
                h3 {
                  font-size: 1.5rem;
                  font-weight: bold;
                  color: #FFFFFF;
                  text-align: center;
                  margin-top: 25px;

                  a {
                    display: none;
                  }
                }

                .model-tab {
                  margin-left: 0;
                  padding: 0;
                  box-shadow: none;
                  border: unset;
                  background-color: unset !important;

                  label {
                    margin-bottom: 0;
                    position: relative;
                    margin-top: 10px;

                    &:before {
                      transition: background 0.5s;
                      z-index: 1;
                      content: " ";
                      position: absolute;
                      display: block;
                      background: #999999;
                      width: 10px;
                      height: 2px;
                      top: 0;
                      right: 22px;
                      bottom: 0;
                      margin: auto;
                    }

                    &:after {
                      transition: background 0.5s;
                      z-index: 1;
                      content: " ";
                      position: absolute;
                      display: block;
                      background: #999999;
                      width: 2px;
                      height: 10px;
                      top: 0;
                      right: 26px;
                      bottom: 0;
                      margin: auto;
                    }
                  }

                  .car-item-footer {
                    padding-left: 0;
                  }

                  &.model-options {
                    h4 {
                      text-align: left;
                      padding-left: 15px;
                      font-size: 0.875rem;
                      line-height: 3rem;
                      background: #607D8B;
                      color: #FFFFFF;
                      margin-bottom: 0;

                      &:before {
                        content: " ";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 15px;
                        margin: auto;
                        display: block;
                        background: #FFFFFF;
                        width: 24px;
                        height: 24px;
                        border-radius: 24px;
                        transition: background 0.5s;
                      }
                    }

                    .accordion {
                      background-color: #B8D4E2;
                      max-height: 0px;
                      overflow: hidden;

                      .description-content-list {
                        font-size: 0.875rem;
                        margin: 0;

                        &:first-child {
                          padding-top: 10px;
                        }

                        &:last-child {
                          padding-bottom: 10px;
                        }

                        li {
                          &:before {
                            color: #464646;
                            font-size: 0.875rem;
                          }
                        }
                      }
                    }

                    input {
                      &:checked+label+.accordion {
                        max-height: 1500px;
                        margin-top: unset;
                      }

                      &:checked+label:after {
                        transform: rotate(-270deg);
                      }
                    }

                    label {
                      &:after {
                        transition: transform 0.5s;
                      }
                    }
                  }
                }
              }

              .column-right {
                h3 {
                  font-size: 1.5rem;
                  font-weight: bold;
                  color: #FFFFFF;
                  text-align: center;
                  margin-top: 25px;
                }

                .w-buy {
                  .w-buy-list {
                    li {
                      .w-buy-block {
                        margin-left: 0;
                        margin-right: 0;
                        box-shadow: unset;
                        border: 0;
                        background: #607D8B !important;
                        position: relative;

                        &:before {
                          content: " ";
                          position: absolute;
                          display: block;
                          background: #FFFFFF;
                          width: 20px;
                          height: 20px;
                          border-radius: 20px;
                          top: 0;
                          right: 0;
                          bottom: 0;
                          left: 0;
                          margin: auto 10px auto auto;
                        }

                        h4 {
                          color: #3C5059;
                          position: relative;

                          &:before {
                            content: " ";
                            position: absolute;
                            display: block;
                            background: #999999;
                            width: 10px;
                            height: 2px;
                            z-index: 1;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            margin: 33px 0px auto auto;
                          }

                          &:after {
                            content: " ";
                            position: absolute;
                            display: block;
                            background: #999999;
                            width: 2px;
                            height: 10px;
                            z-index: 1;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            margin: 29px 4px auto auto;
                          }
                        }

                        .w-buy-price {
                          color: #3C5059;
                        }

                        .w-buy-credit,
                        .w-buy-trade-in {
                          border: 1px solid #3C5059;
                        }

                        .w-links {
                          li {
                            display: inline-block;
                            width: 49%;
                            text-align: center;
                            background: #B8D4E2;
                            font-weight: bold;
                            padding: 10px 0;
                            font-size: 0.625rem;

                            a {
                              text-decoration: none;
                              color: #464646;
                            }

                            &:first-child {
                              margin-right: 2%;
                            }
                          }
                        }

                        &.active {
                          &:before {
                            content: none;
                          }

                          h4 {
                            color: #FFFFFF;

                            &:before, &:after {
                              content: none;
                            }
                          }

                          .w-price-online-buy,
                          .w-buy-price {
                            color: #FFFFFF;
                          }

                          .w-buy-credit,
                          .w-buy-trade-in {
                            border: 0;
                          }
                        }
                      }
                    }
                  }
                }

                .w-buy-disclaimer {
                  padding-bottom: 15px;
                  border-bottom: 1px solid #EDEDED;
                }

                .subscribe-form,
                .subscribe-success {
                  margin-right: 0;
                  margin-left: 0;
                  box-shadow: none;
                  border: 0;
                }

                .subscribe-form {
                  padding: 0;

                  &.bg-white {
                    background-color: unset !important;

                    form {
                      background: #607D8B;
                      padding: 10px;
                      margin-right: 15px;

                      &:before {
                        content: " ";
                        display: block;
                        width: 1px;
                        height: 100%;
                        background: #999999;
                        position: absolute;
                        right: -8px;
                      }

                      .form-group {
                        margin-bottom: 0;

                        input[type="email"]::-moz-placeholder {
                          color: #FFFFFF; /* Цвет подсказывающего текста */
                        }
                        input[type="email"]::-webkit-input-placeholder { color: #FFFFFF; }
                        input[type="email"]:-ms-input-placeholder { color: #FFFFFF; }
                        input[type="email"]::-ms-input-placeholder { color: #FFFFFF; }
                        input[type="email"]::placeholder { color: #FFFFFF; }

                        input[type="email"] {
                          background-color: #607D8B;
                          border-color: #FFFFFF;
                          color: #FFFFFF;
                        }
                      }

                      .text-gray {
                        font-size: 0.5rem;
                        color: #FFFFFF;
                      }
                    }

                    .scroll-top {
                      background: #EBEFF2;
                      color: #999999;
                      text-align: center;
                      margin-bottom: 0;
                      margin-right: -15px;
                      position: relative;
                      padding-top: 15px;
                      padding-bottom: 10px;

                      .icon-circle {
                        background: #999999;
                        width: 24px;
                        height: 24px;
                        border-radius: 24px;
                        margin: 0 auto 7px auto;
                        position: relative;

                        &:before {
                          content: " ";
                          position: absolute;
                          display: block;
                          background: #FFFFFF;
                          width: 10px;
                          height: 2px;
                          z-index: 1;
                          top: 0;
                          right: 0;
                          bottom: 0;
                          left: 0;
                          margin: auto;
                        }
                      }

                      span {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .section-hd-car {
    .car-item-current-cont {
      .tabs-panel,
      .tabs-content {
        margin-left: -15px;
        margin-right: -15px;
      }

      .tabs-content {
        padding: 10px 0 20px 0;

        .variant-list {
          .link {
            .variant-info,
            .variant-flags {
              padding-left: 5px;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .section-hd-car {
    .car-item-current-cont {
      .tabs-content {
        .variant-list {
          .link {
            .variant-info,
            .variant-flags {
              padding-right: 0;
              padding-left: 0;
            }

            .variant-flags {
              div {
                font-size: 0.5rem;
                padding: 2px 4px 1px 4px;
              }
            }
          }
        }
      }
    }
  }
}
