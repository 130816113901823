.rec-calc-wrap {
  padding: 30px;

  ul {
    list-style: none;
    padding: unset;
    margin: unset;

    li {
      margin-bottom: 30px;

      &.bnr_calc_place-wrap {
        background: #FFFFFF;
        padding: 15px;
        margin-top: -30px;

        .title {
          display: none;
        }
      }

      &.bnr_calc_days-wrap {
        .title {
          margin-bottom: 10px;
        }

        .bnr_calc_days,
        .bnr_calc_days-conditions {
          line-height: 1.75rem;
          padding-left: 8px;
          cursor: text;
          min-width: 60px;
          margin-bottom: unset;

          label {
            margin-bottom: unset;
          }
        }

        .bnr_calc_days-conditions {
          display: inline-block;

          label {
            margin-left: 6px;
          }
        }

        .rec-days-selector {
          margin-top: 10px;

          .d-line {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            line-height: 2rem;
            margin-bottom: unset;

            &.checked {
              background: #999999;
              padding: 0 10px;

              .link-red, .link-red:hover {
                color: #FFFFFF;
                border-bottom: 0;
              }
            }

            .link-red {
              color: #464646;

              &:hover {
                text-decoration: none;
                color: #EE1C24;
              }
            }
          }
        }

        .bnr_calc_error {
          color: #EE1C24;
          padding-top: 7px;
        }
      }

      .rec-calc-city_groups {
        margin-top: 10px;

        .rec-calc-city-rotations {
          padding-right: 15px;
          padding-left: 15px;

          .rec-calc-city-rotation {
            margin-top: 10px;
            margin-left: 0;
            line-height: 2rem;
            height: 32px;
            margin-bottom: unset;

            label {
              font-size: 0.875rem;
              margin-left: 10px;
              margin-right: 10px;
              margin-bottom: unset;
            }
          }
        }
      }
    }
  }

  h1 {
    font-weight: bold;
    line-height: 1.2;
    font-size: 1.5rem;

    .btn-red {
      font-size: 1.125rem;
      line-height: 1.2;
      padding: 8.5px 10px;
      font-weight: bold;
      border-radius: unset;

      span {
        display: inline-block;
        width: 100%;
      }

      small {
        font-weight: bold;
      }
    }
  }

  .title {
    font-size: 1.125rem;
    font-weight: bold;
  }

  label {
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    margin-right: 6px;
    position: relative;

    &.bnr_calc_kind,
    &.bnr_calc_block_block {
      margin-top: 10px;

      &.checked {
        .arrow-down {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #999999;
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &.bnr_calc_kind,
    &.bnr_calc_block_block,
    &.bnr_calc_place {
      font-size: 1.125rem;
      line-height: 2.375rem;
      padding: 0 10px;
      margin-bottom: unset;

      span {
        border-bottom: 1px dotted #EE1C24;

        &:hover {
          color: #EE1C24;
        }
      }

      &.checked {
        background: #999999;

        span {
          color: #FFFFFF;
          border-bottom: unset;
        }
      }
    }
  }

  input[type="text"] {
    &.styler {
      padding: 3px 9px;
      border: 1px solid #CCCCCC;
      border-bottom-color: #B3B3B3;
      border-radius: 4px;
      box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,0.1);
      color: #333333;
      font: 18px Tahoma, sans-serif;
    }

    &.styler-80 {
      width: 80px;
    }
  }

  .jq-checkbox {
    top: -1px;
    width: 13px;
    height: 13px;
    border: 1px solid #C3C3C3;
    border-radius: 3px;
    background: #F6F6F6;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), inset -1px -1px #FFF, inset 1px -1px #FFF;
    vertical-align: middle;
    cursor: pointer;

    &.checked {
      .jq-checkbox__div {
        width: 9px;
        height: 9px;
        margin: 1px 0 0 1px;
        border-radius: 2px;
        background: #666666;
        box-shadow: inset 0 -3px 6px #AAA;
      }
    }
  }

  .jq-selectbox {
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      .jq-selectbox__select {
        .jq-selectbox__trigger {
          .jq-selectbox__trigger-arrow {
            opacity: 1;
            filter: alpha(opacity=100);
          }
        }
      }
    }

    &.rec-cal-rotation-select {
      margin-top: -5px;
    }

    .jq-selectbox__select {
      height: 30px;
      padding: 0 45px 0 10px;
      border: 1px solid #CCCCCC;
      border-bottom-color: #B3B3B3;
      border-radius: 4px;
      background: #FFFFFF;
      box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,0.1);
      color: #333333;
      text-shadow: 1px 1px #FFFFFF;
      font: 1.125rem/1.875rem Tahoma, sans-serif;

      .jq-selectbox__trigger {
        position: absolute;
        top: 0;
        right: 0;
        width: 34px;
        height: 100%;

        .jq-selectbox__trigger-arrow {
          position: absolute;
          top: 14px;
          right: 12px;
          width: 0;
          height: 0;
          overflow: hidden;
          border-top: 5px solid #000000;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          opacity: 0.3;
          filter: alpha(opacity=30);
        }
      }
    }

    .jq-selectbox__dropdown {
      top: 33px;
      width: 100%;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      border: 1px solid #CCCCCC;
      border-radius: 4px;
      background: #FFFFFF;
      box-shadow: 0 2px 10px rgba(0,0,0,0.2);
      font: 1.125rem/1.125rem Tahoma, sans-serif;

      .jq-selectbox__search {
        margin: 5px;
      }

      .jq-selectbox__not-found {
        margin: 5px;
        padding: 5px 8px 6px;
        background: #F0F0F0;
        font-size: 0.8125rem;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          min-height: 18px;
          padding: 5px 10px 6px;
          color: #231F20;
          margin-bottom: unset;

          &.selected {
            background-color: #A3ABB1;
            color: #FFFFFF;
          }

          &:hover {
            background-color: #EE1C24;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .rec-calc-chek-wrap {
    background-color: #FFFFFF;
    padding: 20px 30px;
    margin-top: 50px;

    .rec-calc-info {
      margin-bottom: 35px;

      span {
        display: block;
      }

      .title {
        line-height: 1.4;
        font-size: 1.125rem;
        font-weight: normal;
      }

      &:first-of-type {
        .rec-calc-amount {
          margin-top: 7px;
          font-weight: bold;
          font-size: 1.125rem;
        }

        .rec-calc-notice {
          margin-top: 3px;
        }
      }

      .rec-calc-discount {
        font-size: 1.125rem;
      }

      &.rec-discount-element {
        .title {
          font-size: 0.75rem;
        }
      }

      .rec-calc-total {
        font-size: 1.5rem;
        margin-top: 10px;
        font-weight: bold;
      }
    }

    .rec-calc-contact-form {
      .b-red {
        border: 1px solid #EE1C24;
      }
    }

    .rec-calc-buttons {
      font-size: 1.125rem;
      line-height: 1.4;
    }

    .rec-calc-contact-form-sended, .rec-calc-contact-form-error {
      margin-top: 18px;
    }

    .rec-calc-chek-right {
      label {
        cursor: text;
        display: block;
        font-weight: bold;
        margin: 18px 0 5px 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .rec-calc-wrap {
    .rec-calc-chek-wrap {
      background: #FFFFFF url(../../../../images/frontend/rec-calc-chek-bg.jpg) 45% 0 repeat-y;
    }
  }
}

@media (max-width: 991px) {
  .rec-calc-wrap {
    .rec-calc-chek-wrap {
      .rec-calc-chek-right {
        .rec-calc-contact-form {
          .btn-red {
            width: 100%;
            text-transform: uppercase;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}