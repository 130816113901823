.tabs-content-description {
  padding-top: 20px;
}

.model-calcs {
  overflow: hidden;

  .model-calc {
    display: none;
    position: relative;
    float: left;
    width: 100%;
    margin-right: -100%;

    &.model-calc-active {
      display: block;
      margin-left: 0;

      .model-calc-header {
        border: 2px solid #f9b33b;
        background-color: #fadeaf !important;
        height: 115px;
        display: table;
        width: 100%;
        position: relative;
        text-decoration: none;

        .model-calc-header-arrow {
          font-size: 1.5rem;
          line-height: 1.4;

          .text-white {
            display: inline-block;
            position: relative;
            background-color: #FF6014;
            border-bottom: 1px dotted #FFFFFF;
            padding: 8px 15px 8px 30px;
            margin-left: -15px;

            &:after {
              content: '';
              position: absolute;
              right: -45px;
              top: 0;
              border: 25px solid transparent;
              border-left: 20px solid #FF6014;
            }

            p {
              margin: unset;
              border-bottom: 1px dotted #FFFFFF;
            }
          }
        }

        .model-calc-header-title {
          .link-red {
            font-size: 1.5rem;
            line-height: 1.75rem;
            color: #464646;
          }

          .text-gray {
            font-size: 0.875rem;
            line-height: 2;
          }
        }

        div {
          display: table-cell;
          vertical-align: middle;
        }

        &:hover {
          .model-calc-header-arrow {
            .text-white {
              p {
                color: #FDB;
                border-color: #FDB;
              }
            }
          }

          .model-calc-header-title {
            .link-red {
              color: #EE1C24;
            }
          }
        }
      }
    }
  }
}

.all-model-calcs-list {
  .model-calcs-list {
    .navbar {
      padding: unset;

      ul {
        padding: unset;
        margin: unset;

        li {
          padding: 0.5rem 1rem;

          a {
            &.nav-link {
              color: #FD9C6E;
              text-decoration: none;
              border-bottom: 1px dotted #FF6014;
              padding: unset;

              &:hover {
                color: #FF6014;
              }

              &.active {
                color: #FF6014;
                font-weight: bold;
                border: 0;
              }
            }
          }
        }
      }
    }
  }
}

.model-calc-form {
  &.universal {
    margin-bottom: 10px;

    .universal-calc {
      .universal-calc__section {
        margin-top: 30px;

        &:first-child {
          margin-top: unset;
        }

        .universal-calc__title {
          font-size: 0.75rem;
          text-transform: uppercase;

          .universal-calc__text-default {
            color: #464646;
            text-transform: none;
          }
        }

        .universal-calc__current {
          font-size: 1.25rem;
          line-height: 1;
          font-weight: bold;
        }

        .universal-calc__total {
          font-size: 2.5rem;
          line-height: 1;
          font-weight: bold;
        }
      }

      .universal-calc__left {
        padding: 25px 15px 30px;
        background-color: #F9B33B;
        position: relative;

        .universal-calc__text-important {
          color: #996100;
        }

        .universal-calc__additional {
          border: 1px solid #FADEAF;
          border-radius: 4px;
          padding: 12px 20px;

          .universal-calc__checkbox-group {
            .universal-calc__checkbox {
              .universal-calc__services-input {
                vertical-align: middle;
              }

              span {
                vertical-align: text-bottom;
                margin-left: 10px;
              }
            }
          }
        }

        .universal-calc__months {
          display: flex;
          width: 100%;
          justify-content: space-between;
          font-size: 1.25rem;
          border: 1px solid #FADEAF;
          border-radius: 4px;
          overflow: hidden;
          margin-top: 15px;
          position: relative;

          .universal-calc__months-input {
            display: none;

            &:checked + label {
              background: #fadeaf;
            }
          }

          label {
            font-size: 1.25rem;
            width: 100%;
            cursor: pointer;
            text-align: center;
            padding: 12px 0;
            position: relative;
            z-index: 2;
            line-height: 1.2;
            margin: 0;

            &:hover {
              background: rgba(0,0,0,0.07);
            }
          }
        }

        /**
        Ion.RangeSlider, 2.3.0
        © Denis Ineshin, 2010 - 2018, IonDen.com
        Build date: 2018-12-12 00:07:25
        */
        .irs {
          position: relative;
          display: block;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          font-size: 12px;
          font-family: Arial, sans-serif;
        }

        .irs-line {
          position: relative;
          display: block;
          overflow: hidden;
          outline: none !important;
        }

        .irs-bar {
          position: absolute;
          display: block;
          left: 0;
          width: 0;
        }

        .irs-shadow {
          position: absolute;
          display: none;
          left: 0;
          width: 0;
        }

        .irs-handle {
          position: absolute;
          display: block;
          box-sizing: border-box;
          cursor: default;
          z-index: 1;
        }

        .irs-handle.type_last {
          z-index: 2;
        }

        .irs-min,
        .irs-max {
          position: absolute;
          display: block;
          cursor: default;
        }

        .irs-min {
          left: 0;
        }

        .irs-max {
          right: 0;
        }

        .irs-from,
        .irs-to,
        .irs-single {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          cursor: default;
          white-space: nowrap;
        }

        .irs-grid {
          position: absolute;
          display: none;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 20px;
        }

        .irs-with-grid .irs-grid {
          display: block;
        }

        .irs-grid-pol {
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 8px;
          background: #000;
        }

        .irs-grid-pol.small {
          height: 4px;
        }

        .irs-grid-text {
          position: absolute;
          bottom: 0;
          left: 0;
          white-space: nowrap;
          text-align: center;
          font-size: 9px;
          line-height: 9px;
          padding: 0 3px;
          color: #000;
        }

        .irs-disable-mask {
          position: absolute;
          display: block;
          top: 0;
          left: -1%;
          width: 102%;
          height: 100%;
          cursor: default;
          background: rgba(0, 0, 0, 0);
          z-index: 2;
        }

        .lt-ie9 .irs-disable-mask {
          background: #000;
          filter: alpha(opacity=0);
          cursor: not-allowed;
        }

        .irs-disabled {
          opacity: 0.4;
        }

        .irs-hidden-input {
          position: absolute !important;
          display: block !important;
          top: 0 !important;
          left: 0 !important;
          width: 0 !important;
          height: 0 !important;
          font-size: 0 !important;
          line-height: 0 !important;
          padding: 0 !important;
          margin: 0 !important;
          overflow: hidden;
          outline: none !important;
          z-index: -9999 !important;
          background: none !important;
          border-style: solid !important;
          border-color: transparent !important;
        }

        .irs--flat {
          height: 40px;
        }

        .irs--flat.irs-with-grid {
          height: 60px;
        }

        .irs--flat .irs-line {
          top: 25px;
          height: 12px;
          background-color: #e1e4e9;
          border-radius: 4px;
        }

        .irs--flat .irs-bar {
          top: 25px;
          height: 12px;
          background-color: #ed5565;
        }

        .irs--flat .irs-bar--single {
          border-radius: 4px 0 0 4px;
        }

        .irs--flat .irs-shadow {
          height: 1px;
          bottom: 16px;
          background-color: #e1e4e9;
        }

        .irs--flat .irs-handle {
          top: 22px;
          width: 16px;
          height: 18px;
          background-color: transparent;
        }

        .irs--flat .irs-handle > i:first-child {
          position: absolute;
          display: block;
          top: 0;
          left: 50%;
          width: 2px;
          height: 100%;
          margin-left: -1px;
          background-color: #da4453;
        }

        .irs--flat .irs-handle.state_hover > i:first-child,
        .irs--flat .irs-handle:hover > i:first-child {
          background-color: #a43540;
        }

        .irs--flat .irs-min,
        .irs--flat .irs-max {
          top: 0;
          padding: 1px 3px;
          color: #999;
          font-size: 10px;
          line-height: 1.333;
          text-shadow: none;
          background-color: #e1e4e9;
          border-radius: 4px;
        }

        .irs--flat .irs-from,
        .irs--flat .irs-to,
        .irs--flat .irs-single {
          color: white;
          font-size: 10px;
          line-height: 1.333;
          text-shadow: none;
          padding: 1px 5px;
          background-color: #ed5565;
          border-radius: 4px;
        }

        .irs--flat .irs-from:before,
        .irs--flat .irs-to:before,
        .irs--flat .irs-single:before {
          position: absolute;
          display: block;
          content: "";
          bottom: -6px;
          left: 50%;
          width: 0;
          height: 0;
          margin-left: -3px;
          overflow: hidden;
          border: 3px solid transparent;
          border-top-color: #ed5565;
        }

        .irs--flat .irs-grid-pol {
          background-color: #e1e4e9;
        }

        .irs--flat .irs-grid-text {
          color: #999;
        }

        .irs--big {
          height: 55px;
        }

        .irs--big.irs-with-grid {
          height: 70px;
        }

        .irs--big .irs-line {
          top: 33px;
          height: 12px;
          background-color: white;
          background: linear-gradient(to bottom, #ddd -50%, white 150%);
          border: 1px solid #ccc;
          border-radius: 12px;
        }

        .irs--big .irs-bar {
          top: 33px;
          height: 12px;
          background-color: #92bce0;
          border: 1px solid #428bca;
          background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
          box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5);
        }

        .irs--big .irs-bar--single {
          border-radius: 12px 0 0 12px;
        }

        .irs--big .irs-shadow {
          height: 1px;
          bottom: 16px;
          background-color: rgba(66, 139, 202, 0.5);
        }

        .irs--big .irs-handle {
          top: 25px;
          width: 30px;
          height: 30px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          background-color: #cbcfd5;
          background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
          border-radius: 30px;
        }

        .irs--big .irs-handle.state_hover,
        .irs--big .irs-handle:hover {
          border-color: rgba(0, 0, 0, 0.45);
          background-color: #939ba7;
          background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%);
        }

        .irs--big .irs-min,
        .irs--big .irs-max {
          top: 0;
          padding: 1px 5px;
          color: white;
          text-shadow: none;
          background-color: #9f9f9f;
          border-radius: 3px;
        }

        .irs--big .irs-from,
        .irs--big .irs-to,
        .irs--big .irs-single {
          color: white;
          text-shadow: none;
          padding: 1px 5px;
          background-color: #428bca;
          background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
          border-radius: 3px;
        }

        .irs--big .irs-grid-pol {
          background-color: #428bca;
        }

        .irs--big .irs-grid-text {
          color: #428bca;
        }

        .irs--modern {
          height: 55px;
        }

        .irs--modern.irs-with-grid {
          height: 55px;
        }

        .irs--modern .irs-line {
          top: 25px;
          height: 5px;
          background-color: #d1d6e0;
          background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
          border: 1px solid #a3adc1;
          border-bottom-width: 0;
          border-radius: 5px;
        }

        .irs--modern .irs-bar {
          top: 25px;
          height: 5px;
          background: #20b426;
          background: linear-gradient(to bottom, #20b426 0%, #18891d 100%);
        }

        .irs--modern .irs-bar--single {
          border-radius: 5px 0 0 5px;
        }

        .irs--modern .irs-shadow {
          height: 1px;
          bottom: 21px;
          background-color: rgba(209, 214, 224, 0.5);
        }

        .irs--modern .irs-handle {
          top: 37px;
          width: 12px;
          height: 13px;
          border: 1px solid #a3adc1;
          border-top-width: 0;
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
          border-radius: 0 0 3px 3px;
        }

        .irs--modern .irs-handle > i:nth-child(1) {
          position: absolute;
          display: block;
          top: -4px;
          left: 1px;
          width: 6px;
          height: 6px;
          border: 1px solid #a3adc1;
          background: white;
          transform: rotate(45deg);
        }

        .irs--modern .irs-handle > i:nth-child(2) {
          position: absolute;
          display: block;
          box-sizing: border-box;
          top: 0;
          left: 0;
          width: 10px;
          height: 12px;
          background: #e9e6e6;
          background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
          border-radius: 0 0 3px 3px;
        }

        .irs--modern .irs-handle > i:nth-child(3) {
          position: absolute;
          display: block;
          box-sizing: border-box;
          top: 3px;
          left: 3px;
          width: 4px;
          height: 5px;
          border-left: 1px solid #a3adc1;
          border-right: 1px solid #a3adc1;
        }

        .irs--modern .irs-handle.state_hover,
        .irs--modern .irs-handle:hover {
          border-color: #7685a2;
          background: #c3c7cd;
          background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%);
        }

        .irs--modern .irs-handle.state_hover > i:nth-child(1),
        .irs--modern .irs-handle:hover > i:nth-child(1) {
          border-color: #7685a2;
        }

        .irs--modern .irs-handle.state_hover > i:nth-child(3),
        .irs--modern .irs-handle:hover > i:nth-child(3) {
          border-color: #48536a;
        }

        .irs--modern .irs-min,
        .irs--modern .irs-max {
          top: 0;
          font-size: 10px;
          line-height: 1.333;
          text-shadow: none;
          padding: 1px 5px;
          color: white;
          background-color: #d1d6e0;
          border-radius: 5px;
        }

        .irs--modern .irs-from,
        .irs--modern .irs-to,
        .irs--modern .irs-single {
          font-size: 10px;
          line-height: 1.333;
          text-shadow: none;
          padding: 1px 5px;
          background-color: #20b426;
          color: white;
          border-radius: 5px;
        }

        .irs--modern .irs-from:before,
        .irs--modern .irs-to:before,
        .irs--modern .irs-single:before {
          position: absolute;
          display: block;
          content: "";
          bottom: -6px;
          left: 50%;
          width: 0;
          height: 0;
          margin-left: -3px;
          overflow: hidden;
          border: 3px solid transparent;
          border-top-color: #20b426;
        }

        .irs--modern .irs-grid {
          height: 25px;
        }

        .irs--modern .irs-grid-pol {
          background-color: #dedede;
        }

        .irs--modern .irs-grid-text {
          color: silver;
          font-size: 13px;
        }

        .irs--sharp {
          height: 50px;
          font-size: 12px;
          line-height: 1;
        }

        .irs--sharp.irs-with-grid {
          height: 57px;
        }

        .irs--sharp .irs-line {
          top: 30px;
          height: 2px;
          background-color: black;
          border-radius: 2px;
        }

        .irs--sharp .irs-bar {
          top: 30px;
          height: 2px;
          background-color: #ee22fa;
        }

        .irs--sharp .irs-bar--single {
          border-radius: 2px 0 0 2px;
        }

        .irs--sharp .irs-shadow {
          height: 1px;
          bottom: 21px;
          background-color: rgba(0, 0, 0, 0.5);
        }

        .irs--sharp .irs-handle {
          top: 25px;
          width: 10px;
          height: 10px;
          background-color: #a804b2;
        }

        .irs--sharp .irs-handle > i:first-child {
          position: absolute;
          display: block;
          top: 100%;
          left: 0;
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-top-color: #a804b2;
        }

        .irs--sharp .irs-handle.state_hover,
        .irs--sharp .irs-handle:hover {
          background-color: black;
        }

        .irs--sharp .irs-handle.state_hover > i:first-child,
        .irs--sharp .irs-handle:hover > i:first-child {
          border-top-color: black;
        }

        .irs--sharp .irs-min,
        .irs--sharp .irs-max {
          color: white;
          font-size: 14px;
          line-height: 1;
          top: 0;
          padding: 3px 4px;
          opacity: 0.4;
          background-color: #a804b2;
          border-radius: 2px;
        }

        .irs--sharp .irs-from,
        .irs--sharp .irs-to,
        .irs--sharp .irs-single {
          font-size: 14px;
          line-height: 1;
          text-shadow: none;
          padding: 3px 4px;
          background-color: #a804b2;
          color: white;
          border-radius: 2px;
        }

        .irs--sharp .irs-from:before,
        .irs--sharp .irs-to:before,
        .irs--sharp .irs-single:before {
          position: absolute;
          display: block;
          content: "";
          bottom: -6px;
          left: 50%;
          width: 0;
          height: 0;
          margin-left: -3px;
          overflow: hidden;
          border: 3px solid transparent;
          border-top-color: #a804b2;
        }

        .irs--sharp .irs-grid {
          height: 25px;
        }

        .irs--sharp .irs-grid-pol {
          background-color: #dedede;
        }

        .irs--sharp .irs-grid-text {
          color: silver;
          font-size: 13px;
        }

        .irs--round {
          height: 50px;
        }

        .irs--round.irs-with-grid {
          height: 65px;
        }

        .irs--round .irs-line {
          top: 36px;
          height: 4px;
          background-color: #dee4ec;
          border-radius: 4px;
        }

        .irs--round .irs-bar {
          top: 36px;
          height: 4px;
          background-color: #006cfa;
        }

        .irs--round .irs-bar--single {
          border-radius: 4px 0 0 4px;
        }

        .irs--round .irs-shadow {
          height: 4px;
          bottom: 21px;
          background-color: rgba(222, 228, 236, 0.5);
        }

        .irs--round .irs-handle {
          top: 26px;
          width: 24px;
          height: 24px;
          border: 4px solid #006cfa;
          background-color: white;
          border-radius: 24px;
          box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
        }

        .irs--round .irs-handle.state_hover,
        .irs--round .irs-handle:hover {
          background-color: #f0f6ff;
        }

        .irs--round .irs-min,
        .irs--round .irs-max {
          color: #333;
          font-size: 14px;
          line-height: 1;
          top: 0;
          padding: 3px 5px;
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 4px;
        }

        .irs--round .irs-from,
        .irs--round .irs-to,
        .irs--round .irs-single {
          font-size: 14px;
          line-height: 1;
          text-shadow: none;
          padding: 3px 5px;
          background-color: #006cfa;
          color: white;
          border-radius: 4px;
        }

        .irs--round .irs-from:before,
        .irs--round .irs-to:before,
        .irs--round .irs-single:before {
          position: absolute;
          display: block;
          content: "";
          bottom: -6px;
          left: 50%;
          width: 0;
          height: 0;
          margin-left: -3px;
          overflow: hidden;
          border: 3px solid transparent;
          border-top-color: #006cfa;
        }

        .irs--round .irs-grid {
          height: 25px;
        }

        .irs--round .irs-grid-pol {
          background-color: #dedede;
        }

        .irs--round .irs-grid-text {
          color: silver;
          font-size: 13px;
        }

        .irs--square {
          height: 50px;
        }

        .irs--square.irs-with-grid {
          height: 60px;
        }

        .irs--square .irs-line {
          top: 31px;
          height: 4px;
          background-color: #dedede;
        }

        .irs--square .irs-bar {
          top: 31px;
          height: 4px;
          background-color: black;
        }

        .irs--square .irs-shadow {
          height: 2px;
          bottom: 21px;
          background-color: #dedede;
        }

        .irs--square .irs-handle {
          top: 25px;
          width: 16px;
          height: 16px;
          border: 3px solid black;
          background-color: white;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        .irs--square .irs-handle.state_hover,
        .irs--square .irs-handle:hover {
          background-color: #f0f6ff;
        }

        .irs--square .irs-min,
        .irs--square .irs-max {
          color: #333;
          font-size: 14px;
          line-height: 1;
          top: 0;
          padding: 3px 5px;
          background-color: rgba(0, 0, 0, 0.1);
        }

        .irs--square .irs-from,
        .irs--square .irs-to,
        .irs--square .irs-single {
          font-size: 14px;
          line-height: 1;
          text-shadow: none;
          padding: 3px 5px;
          background-color: black;
          color: white;
        }

        .irs--square .irs-grid {
          height: 25px;
        }

        .irs--square .irs-grid-pol {
          background-color: #dedede;
        }

        .irs--square .irs-grid-text {
          color: silver;
          font-size: 11px;
        }

        /*
        * Custom styles
        */

        /* irs-with-grid */
        .irs-with-grid {
          margin: 7px 0 20px;
        }

        /* irs-grid */
        .irs-grid {
          bottom: 7px;
          height: 11px;
        }

        .irs-grid-pol {
          top: -23px;
          height: 23px;
          border-top: 4px solid #464646;
          border-bottom: 4px solid #464646;
        }

        .irs-grid-pol.small {
          display: none;
        }

        .irs--flat .irs-grid-text {
          color: #464646;
        }

        .irs-grid-text {
          font-size: 10px;
        }

        .irs--flat .irs-grid-text:after {
          content: '%';
        }

        .irs--flat .irs-grid-pol {
          background: none;
        }

        /* irs, irs-line, irs-single*/
        .irs {
          font-family: Tahoma, Verdana, Segoe, sans-serif;
        }

        .irs--flat .irs-line {
          top: 25px;
          height: 11px;
          background-color: #fadeaf;
        }

        .irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
          color: #464646;
          font-size: 22px;
          font-weight: bold;
          line-height: 1;
          padding: 10px 0 0;
          background-color: #fff;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          text-align: center;
          box-sizing: border-box;
          border: 5px solid rgba(255, 255, 255, .6);
          background-clip: padding-box;
          z-index: 1;
        }

        .irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
          display: none;
        }

        .irs--flat .irs-from:after, .irs--flat .irs-to:after, .irs--flat .irs-single:after {
          content: '%';
          display: block;
          font-size: 9px;
        }

        /* irs-bar */
        .irs--flat .irs-bar {
          top: 25px;
          height: 11px;
          background-color: #464646;
        }

        .irs--flat .irs-handle,
        .irs-min,
        .irs-max {
          display: none;
        }
      }

      .universal-calc__right {
        background-color: #FADEAF;
        padding: 25px 30px 30px;
        position: relative;

        &.with-iframe {
          padding: 0;

          iframe {
            border: 1px solid #F9B33B;
            background: #FFFFFF;
            padding: 25px 17px;
          }
        }

        .universal-calc__close {
          position: absolute;
          width: 20px;
          height: 20px;
          overflow: hidden;
          text-indent: 100%;
          white-space: nowrap;
          font-size: 0;
          border: 0;
          top: 22px;
          right: 20px;
          cursor: pointer;
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkZBRERCQ0Y5NDI1NTExRTk5RDk2RjU2ODBFNjFCQTNBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkZBRERCQ0ZBNDI1NTExRTk5RDk2RjU2ODBFNjFCQTNBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RkFEREJDRjc0MjU1MTFFOTlEOTZGNTY4MEU2MUJBM0EiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RkFEREJDRjg0MjU1MTFFOTlEOTZGNTY4MEU2MUJBM0EiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5CWjmrAAAAp0lEQVR42qTUQQqEMAwF0Cr/Ft17vQHFWzgHmAs6x6gNdKE1afppIASqPrL4dfp9lm8I4cwtc6TW3HEu2JF7G8RkoT9umx1lsptu5dtdJiqERR+YHOD2kEVfWA0yqIppYA9qYhbYQptYC9TQ4GEeqKFNTGomM5e8F0BEI/VECmzOPBQk5uYUTGh7UJCYhk7W1evFrE0fPwcWM1EMYCoqYBzAajReAgwANadBMSL+XPwAAAAASUVORK5CYII=');
        }

        .credit-universal-calc-send-form {
          .card {
            margin-top: 30px;

            table {
              width: 100%;

              tr {
                td {
                  padding-top: 15px;
                  padding-bottom: 15px;
                  border-bottom: 1px solid #CCCCCC;

                  &:last-child {
                    text-align: right;
                    font-size: 1.125rem;
                  }
                }

                &:first-child {
                  td {
                    padding-top: unset;
                    padding-bottom: 15px;
                  }
                }

                &:last-child {
                  td {
                    padding-top: 15px;
                    padding-bottom: unset;
                    border-bottom: unset;
                  }
                }

                td {

                }
              }
            }

            .card-pointer {
              position: absolute;
              top: -30px;
              left: 30px;
              content: '';
              height: 0;
              width: 0;
              pointer-events: none;
              border: transparent solid;
              border-bottom-color: #FFFFFF;
              border-width: 15px;
            }
          }
        }

        .model-calc-form-complete {
          display: none;

          .universal-calc__title-large {
            color: #464646;
            font-size: 1.25rem;
            font-weight: normal;
            line-height: 1;
          }
        }

        .universal-calc__input-group {
          margin-top: 20px;
        }

        .universal-calc__section {
          .universal-calc__agree {
            label {
              font-size: 0.875rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .model-calcs {
    .model-calc-active {
      .model-calc-header {
        .model-calc-header-arrow {
          width: 25%;
        }

        .model-calc-header-title {
          width: 50%;
        }

        .model-calc-header-logo {
          width: 25%;
        }
      }

      .universal-calc__left {
        &:after {
          left: 100%;
          top: 20px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          z-index: 1;
          pointer-events: none;
          border-color: rgba(249,179,59,0);
          border-left-color: #F9B33B;
          border-width: 20px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .model-calcs {
    .model-calc {
      &.model-calc-active {
        .model-calc-header {
          display: grid;
          border: 3px solid #FF6500;
          height: auto;
          text-align: center;

          div {
            width: 100%;
            display: block;
          }

          .model-calc-header-arrow {
            font-size: 1rem;
            line-height: 1.2;
            order: 1;

            .text-white {
              display: inline-block;
              margin: 0 auto;
              width: auto;
              padding: 5px 15px;

              &:after {
                display: none;
              }

              p {
                border: unset;
              }
            }
          }

          .model-calc-header-title {
            order: 3;
            margin-bottom: 15px;

            .link-red {
              font-size: 1rem;
              margin-left: 0%;
            }

            .text-gray {
              margin-left: 0%;
            }
          }

          .model-calc-header-logo {
            order: 2;
            margin: 15px 0;
          }
        }

        .universal-calc__section {
          &:first-child {
            .universal-calc__text-right {
              margin-top: 10px;
            }
          }

          .universal-calc__current, .universal-calc__total {
            line-height: 1.4;
          }
        }

        .model-calc-form {
          &.universal {
            .universal-calc__left {
              .text-right {
                text-align: left !important;
              }

              &:after {
                left: 50%;
                top: 100%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                z-index: 1;
                pointer-events: none;
                border-color: rgba(249,179,59,0);
                border-top-color: #F9B33B;
                border-width: 20px;
                margin: 0 0 0 -20px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 475px) {
  .all-model-calcs-list {
    .model-calcs-list {
      .navbar {
        ul {
          display: block;
          width: 100%;

          li {
            display: inline-block;
            width: 50%;

            a {
              &.nav-link {
                border: unset;
              }
            }
          }
        }
      }
    }
  }
}
