&[data-value] a:before { background-image: url(./../../../../../images/frontend/brands/sprites/48x48.png); width: 48px; height: 48px; background-position: -7140px 0px;}
&[data-value="acura"] a:before { background-position: 0px 0px; }
&[data-value="aito"] a:before { background-position: -68px 0px; }
&[data-value="alfa-romeo"] a:before { background-position: -136px 0px; }
&[data-value="ambertruck"] a:before { background-position: -204px 0px; }
&[data-value="aston-martin"] a:before { background-position: -272px 0px; }
&[data-value="audi"] a:before { background-position: -340px 0px; }
&[data-value="avant"] a:before { background-position: -408px 0px; }
&[data-value="baic"] a:before { background-position: -476px 0px; }
&[data-value="belarus"] a:before { background-position: -544px 0px; }
&[data-value="belgee"] a:before { background-position: -612px 0px; }
&[data-value="bentley"] a:before { background-position: -680px 0px; }
&[data-value="bestune"] a:before { background-position: -748px 0px; }
&[data-value="bmw"] a:before { background-position: -816px 0px; }
&[data-value="brilliance"] a:before { background-position: -884px 0px; }
&[data-value="bugatti"] a:before { background-position: -952px 0px; }
&[data-value="byd"] a:before { background-position: -1020px 0px; }
&[data-value="cadillac"] a:before { background-position: -1088px 0px; }
&[data-value="changan"] a:before { background-position: -1156px 0px; }
&[data-value="chelyabinets"] a:before { background-position: -1224px 0px; }
&[data-value="chery"] a:before { background-position: -1292px 0px; }
&[data-value="chevrolet"] a:before { background-position: -1360px 0px; }
&[data-value="chrysler"] a:before { background-position: -1428px 0px; }
&[data-value="cimc"] a:before { background-position: -1496px 0px; }
&[data-value="citroen"] a:before { background-position: -1564px 0px; }
&[data-value="cukurova"] a:before { background-position: -1632px 0px; }
&[data-value="daewoo"] a:before { background-position: -1700px 0px; }
&[data-value="daf"] a:before { background-position: -1768px 0px; }
&[data-value="datsun"] a:before { background-position: -1836px 0px; }
&[data-value="dfm"] a:before { background-position: -1904px 0px; }
&[data-value="dfsk"] a:before { background-position: -1972px 0px; }
&[data-value="dodge"] a:before { background-position: -2040px 0px; }
&[data-value="dongfeng"] a:before { background-position: -2108px 0px; }
&[data-value="doosan"] a:before { background-position: -2176px 0px; }
&[data-value="evolute"] a:before { background-position: -2244px 0px; }
&[data-value="exeed"] a:before { background-position: -2312px 0px; }
&[data-value="faw"] a:before { background-position: -2380px 0px; }
&[data-value="ferrari"] a:before { background-position: -2448px 0px; }
&[data-value="fiat"] a:before { background-position: -2516px 0px; }
&[data-value="ford-truck"] a:before { background-position: -2584px 0px; }
&[data-value="ford"] a:before { background-position: -2652px 0px; }
&[data-value="forthing"] a:before { background-position: -2720px 0px; }
&[data-value="foton-lovol"] a:before { background-position: -2788px 0px; }
&[data-value="foton"] a:before { background-position: -2856px 0px; }
&[data-value="gac"] a:before { background-position: -2924px 0px; }
&[data-value="galichanin"] a:before { background-position: -2992px 0px; }
&[data-value="gaz"] a:before { background-position: -3060px 0px; }
&[data-value="geely"] a:before { background-position: -3128px 0px; }
&[data-value="genesis"] a:before { background-position: -3196px 0px; }
&[data-value="golden-dragon"] a:before { background-position: -3264px 0px; }
&[data-value="goodsense"] a:before { background-position: -3332px 0px; }
&[data-value="great-wall"] a:before { background-position: -3400px 0px; }
&[data-value="haima"] a:before { background-position: -3468px 0px; }
&[data-value="hangcha"] a:before { background-position: -3536px 0px; }
&[data-value="haval"] a:before { background-position: -3604px 0px; }
&[data-value="hawtai"] a:before { background-position: -3672px 0px; }
&[data-value="heli"] a:before { background-position: -3740px 0px; }
&[data-value="hino"] a:before { background-position: -3808px 0px; }
&[data-value="honda"] a:before { background-position: -3876px 0px; }
&[data-value="hongqi"] a:before { background-position: -3944px 0px; }
&[data-value="howo"] a:before { background-position: -4012px 0px; }
&[data-value="hyundai"] a:before { background-position: -4080px 0px; }
&[data-value="infiniti"] a:before { background-position: -4148px 0px; }
&[data-value="isuzu"] a:before { background-position: -4216px 0px; }
&[data-value="ivanovets"] a:before { background-position: -4284px 0px; }
&[data-value="iveco"] a:before { background-position: -4352px 0px; }
&[data-value="jac"] a:before { background-position: -4420px 0px; }
&[data-value="jaecoo"] a:before { background-position: -4488px 0px; }
&[data-value="jaguar"] a:before { background-position: -4556px 0px; }
&[data-value="jcb"] a:before { background-position: -4624px 0px; }
&[data-value="jeep"] a:before { background-position: -4692px 0px; }
&[data-value="jetour"] a:before { background-position: -4760px 0px; }
&[data-value="jetta"] a:before { background-position: -4828px 0px; }
&[data-value="jingong"] a:before { background-position: -4896px 0px; }
&[data-value="jingonglogo"] a:before { background-position: -4964px 0px; }
&[data-value="kaiyi"] a:before { background-position: -5032px 0px; }
&[data-value="kamaz"] a:before { background-position: -5100px 0px; }
&[data-value="kia"] a:before { background-position: -5168px 0px; }
&[data-value="klintsy"] a:before { background-position: -5236px 0px; }
&[data-value="knewstar"] a:before { background-position: -5304px 0px; }
&[data-value="komatsu"] a:before { background-position: -5372px 0px; }
&[data-value="kraz"] a:before { background-position: -5440px 0px; }
&[data-value="lada"] a:before { background-position: -5508px 0px; }
&[data-value="lamborghini"] a:before { background-position: -5576px 0px; }
&[data-value="land-rover"] a:before { background-position: -5644px 0px; }
&[data-value="lexus"] a:before { background-position: -5712px 0px; }
&[data-value="liaz"] a:before { background-position: -5780px 0px; }
&[data-value="lifan"] a:before { background-position: -5848px 0px; }
&[data-value="lincoln"] a:before { background-position: -5916px 0px; }
&[data-value="liugong"] a:before { background-position: -5984px 0px; }
&[data-value="livan"] a:before { background-position: -6052px 0px; }
&[data-value="lonking"] a:before { background-position: -6120px 0px; }
&[data-value="lotus"] a:before { background-position: -6188px 0px; }
&[data-value="m-hero"] a:before { background-position: -6256px 0px; }
&[data-value="man"] a:before { background-position: -6324px 0px; }
&[data-value="maserati"] a:before { background-position: -6392px 0px; }
&[data-value="maybach"] a:before { background-position: -6460px 0px; }
&[data-value="maz"] a:before { background-position: -6528px 0px; }
&[data-value="mazda"] a:before { background-position: -6596px 0px; }
&[data-value="mercedes-benz"] a:before { background-position: -6664px 0px; }
&[data-value="mg"] a:before { background-position: -6732px 0px; }
&[data-value="mini"] a:before { background-position: -6800px 0px; }
&[data-value="mitsubishi"] a:before { background-position: -6868px 0px; }
&[data-value="moskvich"] a:before { background-position: -6936px 0px; }
&[data-value="nefaz"] a:before { background-position: -7004px 0px; }
&[data-value="nissan"] a:before { background-position: -7072px 0px; }
&[data-value="no-brand"] a:before { background-position: -7140px 0px; }
&[data-value="omoda"] a:before { background-position: -7208px 0px; }
&[data-value="opel"] a:before { background-position: -7276px 0px; }
&[data-value="ora"] a:before { background-position: -7344px 0px; }
&[data-value="oting"] a:before { background-position: -7412px 0px; }
&[data-value="palfinger"] a:before { background-position: -7480px 0px; }
&[data-value="paz"] a:before { background-position: -7548px 0px; }
&[data-value="peugeot"] a:before { background-position: -7616px 0px; }
&[data-value="pontiac"] a:before { background-position: -7684px 0px; }
&[data-value="porsche"] a:before { background-position: -7752px 0px; }
&[data-value="ram"] a:before { background-position: -7820px 0px; }
&[data-value="ravon"] a:before { background-position: -7888px 0px; }
&[data-value="renault"] a:before { background-position: -7956px 0px; }
&[data-value="rolls-royce"] a:before { background-position: -8024px 0px; }
&[data-value="rongwei"] a:before { background-position: -8092px 0px; }
&[data-value="rox"] a:before { background-position: -8160px 0px; }
&[data-value="saab"] a:before { background-position: -8228px 0px; }
&[data-value="samsung"] a:before { background-position: -8296px 0px; }
&[data-value="scania"] a:before { background-position: -8364px 0px; }
&[data-value="schmitz"] a:before { background-position: -8432px 0px; }
&[data-value="scion"] a:before { background-position: -8500px 0px; }
&[data-value="seat"] a:before { background-position: -8568px 0px; }
&[data-value="shacman"] a:before { background-position: -8636px 0px; }
&[data-value="shantui"] a:before { background-position: -8704px 0px; }
&[data-value="skoda"] a:before { background-position: -8772px 0px; }
&[data-value="skywell"] a:before { background-position: -8840px 0px; }
&[data-value="smart"] a:before { background-position: -8908px 0px; }
&[data-value="solaris"] a:before { background-position: -8976px 0px; }
&[data-value="sollers"] a:before { background-position: -9044px 0px; }
&[data-value="soueast"] a:before { background-position: -9112px 0px; }
&[data-value="ssangyong"] a:before { background-position: -9180px 0px; }
&[data-value="subaru"] a:before { background-position: -9248px 0px; }
&[data-value="sunward"] a:before { background-position: -9316px 0px; }
&[data-value="suzuki"] a:before { background-position: -9384px 0px; }
&[data-value="swm"] a:before { background-position: -9452px 0px; }
&[data-value="tank"] a:before { background-position: -9520px 0px; }
&[data-value="tesla"] a:before { background-position: -9588px 0px; }
&[data-value="toyota"] a:before { background-position: -9656px 0px; }
&[data-value="tveks"] a:before { background-position: -9724px 0px; }
&[data-value="uaz"] a:before { background-position: -9792px 0px; }
&[data-value="venucia"] a:before { background-position: -9860px 0px; }
&[data-value="vgv"] a:before { background-position: -9928px 0px; }
&[data-value="volkswagen"] a:before { background-position: -9996px 0px; }
&[data-value="volvo"] a:before { background-position: -10064px 0px; }
&[data-value="voyah"] a:before { background-position: -10132px 0px; }
&[data-value="wecan"] a:before { background-position: -10200px 0px; }
&[data-value="wey"] a:before { background-position: -10268px 0px; }
&[data-value="xcite"] a:before { background-position: -10336px 0px; }
&[data-value="xcmg"] a:before { background-position: -10404px 0px; }
&[data-value="zaz"] a:before { background-position: -10472px 0px; }
&[data-value="zeekr"] a:before { background-position: -10540px 0px; }
&[data-value="zoomlion"] a:before { background-position: -10608px 0px; }
&[data-value="zotye"] a:before { background-position: -10676px 0px; }