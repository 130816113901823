/* model-variant-compare */
.compare-help {
  .movie {
    background-image: url('../../../../images/projects/newauto/compare/start_small.jpg');

    &.started {
      background-image: url('../../../../images/projects/newauto/compare/help_small.gif');
    }
  }

  .start {
    width: 156px;
    height: 49px;
    left: 337px;
    top: 293px;
  }

  .deny {
    width: 156px;
    height: 49px;
    left: 532px;
    top: 293px;
  }
}

@media screen and (min-width: 1200px) and (min-height: 800px) {
  .compare-help {
    .movie {
      background-image: url('../../../../images/projects/newauto/compare/start_big.jpg');

      &.started {
        background-image: url('../../../../images/projects/newauto/compare/help_big.gif');
      }
    }

    .close {
      left: 334px;
    }

    .start {
      left: 465px;
      top: 346px;
    }

    .deny {
      left: 660px;
      top: 346px;
    }
  }
}
