.tile-promo {
  .bnr-body {
    height: inherit;

    .tile-promo-ribbon-special,
    .tile-promo-ribbon-new {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 22;
    }

    .tile-promo-ribbon-special {
      background: url(../../../images/frontend/promo/tile-promo-ribbon-2.png);
    }

    .tile-promo-ribbon-new {
      background: url(../../../images/frontend/promo/tile-promo-ribbon-3.png);
    }

    a {
      .tile-promo-block {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.85);
        left: 10px;
        bottom: 10px;
        border-left: 5px solid #EE1C24;
        z-index: 22;
        color: #464646;

        .tile-promo-title {
          font-weight: bold;
        }

        .tile-promo-price {
          color: #EE1C24;
          margin-top: 0;
        }
      }

      &:hover {
        .tile-promo-block {
          color: #EE1C24;
        }
      }
    }
  }

  .bnr-promo-next {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: url(../../../images/frontend/promo-arrow.png) no-repeat;
    display: inline-block;
    z-index: 22;
    bottom: 10px;
    right: 25px;
    position: absolute;
  }
}

@media (min-width: 992px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-img {
        width: 100%;
        height: auto;
      }

      .tile-promo-block {
        padding: 15px 25px 20px 20px;
      }
    }

    &.car-item-3col {
      .bnr-body {
        .tile-promo-img {
          max-height: 360px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-ribbon-special {
        width: 199px;
        height: 199px;
      }

      .tile-promo-ribbon-new {
        width: 168px;
        height: 167px;
      }

      .tile-promo-block {
        .tile-promo-title {
          font-size: 1.5rem;
          line-height: 2.25rem;
        }

        .tile-promo-price {
          font-size: 1.125rem;
        }

        .tile-promo-subtitle {
          margin-top: 8px;
          font-size: 0.8125rem;
        }
      }
    }
  }

  .block-banner {
    &.bnr-tall {
      text-align: center;
      max-width: 294px;
    }
  }
}

@media (max-width: 991px) {
  .tile-promo {
    margin-bottom: 10px;

    .bnr-body {
      overflow: hidden;

      .tile-promo-img {
        width: auto;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-img {
        height: 303px;
      }

      .tile-promo-block {
        padding: 10px 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-ribbon-special,
      .tile-promo-ribbon-new {
        background-size: contain;
      }

      .tile-promo-img {
        position: inherit;
      }

      .tile-promo-block {
        .tile-promo-title {
          line-height: 1.25rem;
        }

        .tile-promo-price {
          font-size: 0.75rem;
        }

        .tile-promo-subtitle {
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-ribbon-special,
      .tile-promo-ribbon-new {
        width: 120px;
        height: 120px;
      }

      .tile-promo-block {
        padding: 10px 15px;

        .tile-promo-title {
          font-size: 1rem;
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-img {
        height: 240px;
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-img {
        height: 330px;
        left: -25%;
      }
    }
  }
}

@media (max-width: 479px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-ribbon-special,
      .tile-promo-ribbon-new {
        width: 100px;
        height: 100px;
      }

      .tile-promo-img {
        height: 200px;
      }

      .tile-promo-block {
        padding: 5px 10px;

        .tile-promo-title {
          font-size: 0.875rem;
        }
      }
    }
  }
}

@media (min-width: 375px) and (max-width: 479px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-img {
        left: -15%;
      }
    }
  }
}

@media (max-width: 374px) {
  .tile-promo {
    .bnr-body {
      .tile-promo-img {
        left: -40%;
      }
    }
  }
}