.bnr-txt-inner {
  border-top: 5px solid #ee1c24;
  border-bottom: 1px solid #464646;
  height: 100%;
  padding: 0 10px;
  overflow: hidden;
  position: relative;

  &.bnr-body {
    .red {
      margin: 15px 0;
      color: #EE1C24;
    }

    .bnr-txt-title {
      font-size: 1.125rem;
      text-decoration: none;
      color: #464646;
      border-bottom: 1px solid #B0B0B0;
      line-height: 1.2;
      display: initial;

      &:hover {
        cursor: pointer;
        color: #EE1C24;
        border-bottom: 1px solid #EC6A72;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .bnr-txt-inner {
    &.bnr-body {
      .red {
        margin: 5px 0;
        font-size: 0.5625rem;
      }

      p {
        margin-bottom: 5px;
        font-size: 0.5625rem;

        .bnr-txt-title {
          font-size: 0.875rem;
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .bnr-txt-inner {
    &.bnr-body {
      .red {
        margin: 5px 0 10px 0;
      }
    }
  }
}
